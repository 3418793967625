export const logs = [
  // {
  //     title: "offer.janowo.diary.timeline.february",
  //     year: 2024,
  //     pics: [
  //         [
  //         ],
  //         [
  //         ],
  //     ],
  //     alts: [],
  // },
  {
    title: "offer.janowo.diary.timeline.september",
    year: 2024,
    pics: [
      [
        "/assets/images/wiczlino-2/diary/2024-09/20240926_140048.jpg",
        "/assets/images/wiczlino-2/diary/2024-09/20240926_140053.jpg",
        "/assets/images/wiczlino-2/diary/2024-09/20240926_140154.jpg",
        "/assets/images/wiczlino-2/diary/2024-09/20240926_140219.jpg",
        "/assets/images/wiczlino-2/diary/2024-09/20240926_140226.jpg",
        "/assets/images/wiczlino-2/diary/2024-09/20240926_140236.jpg",
        "/assets/images/wiczlino-2/diary/2024-09/20240926_140246.jpg",
        "/assets/images/wiczlino-2/diary/2024-09/20240926_140340.jpg",
        "/assets/images/wiczlino-2/diary/2024-09/20240926_140425.jpg",
        "/assets/images/wiczlino-2/diary/2024-09/20240926_140443.jpg",
        "/assets/images/wiczlino-2/diary/2024-09/20240926_140451.jpg",
        "/assets/images/wiczlino-2/diary/2024-09/20240926_140511.jpg",
        "/assets/images/wiczlino-2/diary/2024-09/20240926_140527.jpg",
        "/assets/images/wiczlino-2/diary/2024-09/20240926_140529.jpg",
        "/assets/images/wiczlino-2/diary/2024-09/20240926_140606.jpg",
        "/assets/images/wiczlino-2/diary/2024-09/20240926_140734.jpg",
        "/assets/images/wiczlino-2/diary/2024-09/20240926_140750.jpg",
        "/assets/images/wiczlino-2/diary/2024-09/20240926_140846.jpg",
        "/assets/images/wiczlino-2/diary/2024-09/20240926_141017.jpg",
      ],
      [
        "/assets/images/wiczlino-2/diary/2024-09/mini_20240926_140048.jpg",
        "/assets/images/wiczlino-2/diary/2024-09/mini_20240926_140053.jpg",
        "/assets/images/wiczlino-2/diary/2024-09/mini_20240926_140154.jpg",
        "/assets/images/wiczlino-2/diary/2024-09/mini_20240926_140219.jpg",
        "/assets/images/wiczlino-2/diary/2024-09/mini_20240926_140226.jpg",
        "/assets/images/wiczlino-2/diary/2024-09/mini_20240926_140236.jpg",
        "/assets/images/wiczlino-2/diary/2024-09/mini_20240926_140246.jpg",
        "/assets/images/wiczlino-2/diary/2024-09/mini_20240926_140340.jpg",
        "/assets/images/wiczlino-2/diary/2024-09/mini_20240926_140425.jpg",
        "/assets/images/wiczlino-2/diary/2024-09/mini_20240926_140443.jpg",
        "/assets/images/wiczlino-2/diary/2024-09/mini_20240926_140451.jpg",
        "/assets/images/wiczlino-2/diary/2024-09/mini_20240926_140511.jpg",
        "/assets/images/wiczlino-2/diary/2024-09/mini_20240926_140527.jpg",
        "/assets/images/wiczlino-2/diary/2024-09/mini_20240926_140529.jpg",
        "/assets/images/wiczlino-2/diary/2024-09/mini_20240926_140606.jpg",
        "/assets/images/wiczlino-2/diary/2024-09/mini_20240926_140734.jpg",
        "/assets/images/wiczlino-2/diary/2024-09/mini_20240926_140750.jpg",
        "/assets/images/wiczlino-2/diary/2024-09/mini_20240926_140846.jpg",
        "/assets/images/wiczlino-2/diary/2024-09/mini_20240926_141017.jpg",
      ],
    ],
    alts: [],
  },
  {
    title: "offer.janowo.diary.timeline.july",
    year: 2024,
    pics: [
      [
        "/assets/images/wiczlino-2/diary/2024-07/20240807_080448.jpg",
        "/assets/images/wiczlino-2/diary/2024-07/20240807_080703.jpg",
        "/assets/images/wiczlino-2/diary/2024-07/20240807_080759.jpg",
        "/assets/images/wiczlino-2/diary/2024-07/20240807_082005.jpg",
        "/assets/images/wiczlino-2/diary/2024-07/20240807_102936.jpg",
        "/assets/images/wiczlino-2/diary/2024-07/20240816_083537.jpg",
      ],
      [
        "/assets/images/wiczlino-2/diary/2024-07/mini_20240807_080448.jpg",
        "/assets/images/wiczlino-2/diary/2024-07/mini_20240807_080703.jpg",
        "/assets/images/wiczlino-2/diary/2024-07/mini_20240807_080759.jpg",
        "/assets/images/wiczlino-2/diary/2024-07/mini_20240807_082005.jpg",
        "/assets/images/wiczlino-2/diary/2024-07/mini_20240807_102936.jpg",
        "/assets/images/wiczlino-2/diary/2024-07/mini_20240816_083537.jpg",
      ],
    ],
    alts: [],
  },
  {
    title: "offer.janowo.diary.timeline.june",
    year: 2024,
    pics: [
      [
        "/assets/images/wiczlino-2/diary/2024-06/IMG_20240617_094300.jpg",
        "/assets/images/wiczlino-2/diary/2024-06/IMG_20240617_094632.jpg",
        "/assets/images/wiczlino-2/diary/2024-06/IMG_20240618_090947.jpg",
        "/assets/images/wiczlino-2/diary/2024-06/IMG_20240621_080215.jpg",
        "/assets/images/wiczlino-2/diary/2024-06/IMG_20240621_080236.jpg",
        "/assets/images/wiczlino-2/diary/2024-06/IMG_20240628_065618.jpg",
        "/assets/images/wiczlino-2/diary/2024-06/IMG_20240628_065817.jpg",
        "/assets/images/wiczlino-2/diary/2024-06/IMG_20240628_161739.jpg",
        "/assets/images/wiczlino-2/diary/2024-06/IMG_20240628_161847.jpg",
      ],
      [
        "/assets/images/wiczlino-2/diary/2024-06/IMG_20240617_094300.jpg",
        "/assets/images/wiczlino-2/diary/2024-06/IMG_20240617_094632.jpg",
        "/assets/images/wiczlino-2/diary/2024-06/IMG_20240618_090947.jpg",
        "/assets/images/wiczlino-2/diary/2024-06/IMG_20240621_080215.jpg",
        "/assets/images/wiczlino-2/diary/2024-06/IMG_20240621_080236.jpg",
        "/assets/images/wiczlino-2/diary/2024-06/IMG_20240628_065618.jpg",
        "/assets/images/wiczlino-2/diary/2024-06/IMG_20240628_065817.jpg",
        "/assets/images/wiczlino-2/diary/2024-06/IMG_20240628_161739.jpg",
        "/assets/images/wiczlino-2/diary/2024-06/IMG_20240628_161847.jpg",
      ],
    ],
    alts: [],
  },
  {
    title: "offer.janowo.diary.timeline.may",
    year: 2024,
    pics: [
      [
        "/assets/images/wiczlino-2/diary/2024-05/20240402_075408.jpg",
        "/assets/images/wiczlino-2/diary/2024-05/20240402_075443.jpg",
        "/assets/images/wiczlino-2/diary/2024-05/20240402_075528.jpg",
        "/assets/images/wiczlino-2/diary/2024-05/20240402_075538.jpg",
        "/assets/images/wiczlino-2/diary/2024-05/20240402_075928.jpg",
        "/assets/images/wiczlino-2/diary/2024-05/20240402_075945.jpg",
        "/assets/images/wiczlino-2/diary/2024-05/20240402_080030.jpg",
        "/assets/images/wiczlino-2/diary/2024-05/20240402_080143.jpg",
        "/assets/images/wiczlino-2/diary/2024-05/20240402_080152.jpg",
        "/assets/images/wiczlino-2/diary/2024-05/20240402_080230.jpg",
      ],
      [
        "/assets/images/wiczlino-2/diary/2024-05/mini_20240402_075408.jpg",
        "/assets/images/wiczlino-2/diary/2024-05/mini_20240402_075443.jpg",
        "/assets/images/wiczlino-2/diary/2024-05/mini_20240402_075528.jpg",
        "/assets/images/wiczlino-2/diary/2024-05/mini_20240402_075538.jpg",
        "/assets/images/wiczlino-2/diary/2024-05/mini_20240402_075928.jpg",
        "/assets/images/wiczlino-2/diary/2024-05/mini_20240402_075945.jpg",
        "/assets/images/wiczlino-2/diary/2024-05/mini_20240402_080030.jpg",
        "/assets/images/wiczlino-2/diary/2024-05/mini_20240402_080143.jpg",
        "/assets/images/wiczlino-2/diary/2024-05/mini_20240402_080152.jpg",
        "/assets/images/wiczlino-2/diary/2024-05/mini_20240402_080230.jpg",
      ],
    ],
    alts: [],
  },
  {
    title: "offer.janowo.diary.timeline.april",
    year: 2024,
    pics: [
      [
        "/assets/images/wiczlino-2/diary/2024-04/20240402_075205.jpg",
        "/assets/images/wiczlino-2/diary/2024-04/20240402_075216.jpg",
        "/assets/images/wiczlino-2/diary/2024-04/20240402_075339.jpg",
        "/assets/images/wiczlino-2/diary/2024-04/20240402_075538.jpg",
        "/assets/images/wiczlino-2/diary/2024-04/20240402_080042.jpg",
        "/assets/images/wiczlino-2/diary/2024-04/20240402_080228.jpg",
        "/assets/images/wiczlino-2/diary/2024-04/20240408_143824.jpg",
        "/assets/images/wiczlino-2/diary/2024-04/20240408_144122.jpg",
        "/assets/images/wiczlino-2/diary/2024-04/20240408_144312.jpg",
        "/assets/images/wiczlino-2/diary/2024-04/20240408_144511.jpg",
        "/assets/images/wiczlino-2/diary/2024-04/20240415_075932.jpg",
        "/assets/images/wiczlino-2/diary/2024-04/20240425_101742.jpg",
        "/assets/images/wiczlino-2/diary/2024-04/20240425_101949.jpg",
        "/assets/images/wiczlino-2/diary/2024-04/20240425_101952.jpg",
        "/assets/images/wiczlino-2/diary/2024-04/20240425_102001.jpg",
        "/assets/images/wiczlino-2/diary/2024-04/20240425_102053.jpg",
      ],
      [
        "/assets/images/wiczlino-2/diary/2024-04/mini_20240402_075205.jpg",
        "/assets/images/wiczlino-2/diary/2024-04/mini_20240402_075216.jpg",
        "/assets/images/wiczlino-2/diary/2024-04/mini_20240402_075339.jpg",
        "/assets/images/wiczlino-2/diary/2024-04/mini_20240402_075538.jpg",
        "/assets/images/wiczlino-2/diary/2024-04/mini_20240402_080042.jpg",
        "/assets/images/wiczlino-2/diary/2024-04/mini_20240402_080228.jpg",
        "/assets/images/wiczlino-2/diary/2024-04/mini_20240408_143824.jpg",
        "/assets/images/wiczlino-2/diary/2024-04/mini_20240408_144122.jpg",
        "/assets/images/wiczlino-2/diary/2024-04/mini_20240408_144312.jpg",
        "/assets/images/wiczlino-2/diary/2024-04/mini_20240408_144511.jpg",
        "/assets/images/wiczlino-2/diary/2024-04/mini_20240415_075932.jpg",
        "/assets/images/wiczlino-2/diary/2024-04/mini_20240425_101742.jpg",
        "/assets/images/wiczlino-2/diary/2024-04/mini_20240425_101949.jpg",
        "/assets/images/wiczlino-2/diary/2024-04/mini_20240425_101952.jpg",
        "/assets/images/wiczlino-2/diary/2024-04/mini_20240425_102001.jpg",
        "/assets/images/wiczlino-2/diary/2024-04/mini_20240425_102053.jpg",
      ],
    ],
    alts: [],
  },
  {
    title: "offer.janowo.diary.timeline.march",
    year: 2024,
    pics: [
      [
        "/assets/images/wiczlino-2/diary/2024-03/20240301_080829.jpg",
        "/assets/images/wiczlino-2/diary/2024-03/20240301_094650.jpg",
        "/assets/images/wiczlino-2/diary/2024-03/20240304_082932.jpg",
        "/assets/images/wiczlino-2/diary/2024-03/20240306_085731.jpg",
        "/assets/images/wiczlino-2/diary/2024-03/20240306_090401.jpg",
        "/assets/images/wiczlino-2/diary/2024-03/20240308_113743.jpg",
        "/assets/images/wiczlino-2/diary/2024-03/20240308_115440.jpg",
        "/assets/images/wiczlino-2/diary/2024-03/20240308_115441.jpg",
        "/assets/images/wiczlino-2/diary/2024-03/20240315_115032.jpg",
        "/assets/images/wiczlino-2/diary/2024-03/20240315_150550.jpg",
        "/assets/images/wiczlino-2/diary/2024-03/20240315_151224.jpg",
        "/assets/images/wiczlino-2/diary/2024-03/20240315_151410.jpg",
      ],
      [
        "/assets/images/wiczlino-2/diary/2024-03/mini_20240301_080829.jpg",
        "/assets/images/wiczlino-2/diary/2024-03/mini_20240301_094650.jpg",
        "/assets/images/wiczlino-2/diary/2024-03/mini_20240304_082932.jpg",
        "/assets/images/wiczlino-2/diary/2024-03/mini_20240306_085731.jpg",
        "/assets/images/wiczlino-2/diary/2024-03/mini_20240306_090401.jpg",
        "/assets/images/wiczlino-2/diary/2024-03/mini_20240308_113743.jpg",
        "/assets/images/wiczlino-2/diary/2024-03/mini_20240308_115440.jpg",
        "/assets/images/wiczlino-2/diary/2024-03/mini_20240308_115441.jpg",
        "/assets/images/wiczlino-2/diary/2024-03/mini_20240315_115032.jpg",
        "/assets/images/wiczlino-2/diary/2024-03/mini_20240315_150550.jpg",
        "/assets/images/wiczlino-2/diary/2024-03/mini_20240315_151224.jpg",
        "/assets/images/wiczlino-2/diary/2024-03/mini_20240315_151410.jpg",
      ],
    ],
    alts: [],
  },
  {
    title: "offer.janowo.diary.timeline.february",
    year: 2024,
    pics: [
      [
        "/assets/images/wiczlino-2/diary/2024-02/20240208_081008.jpg",
        "/assets/images/wiczlino-2/diary/2024-02/20240226_082820.jpg",
        "/assets/images/wiczlino-2/diary/2024-02/20240226_082843.jpg",
        "/assets/images/wiczlino-2/diary/2024-02/20240226_082847.jpg",
        "/assets/images/wiczlino-2/diary/2024-02/IMG_20240226_071238.jpg",
        "/assets/images/wiczlino-2/diary/2024-02/IMG_20240226_071417.jpg",
        "/assets/images/wiczlino-2/diary/2024-02/IMG_20240226_071847.jpg",
        "/assets/images/wiczlino-2/diary/2024-02/IMG_20240226_071848.jpg",
      ],
      [
        "/assets/images/wiczlino-2/diary/2024-02/mini_20240208_081008.jpg",
        "/assets/images/wiczlino-2/diary/2024-02/mini_20240226_082820.jpg",
        "/assets/images/wiczlino-2/diary/2024-02/mini_20240226_082843.jpg",
        "/assets/images/wiczlino-2/diary/2024-02/mini_20240226_082847.jpg",
        "/assets/images/wiczlino-2/diary/2024-02/mini_IMG_20240226_071238.jpg",
        "/assets/images/wiczlino-2/diary/2024-02/mini_IMG_20240226_071417.jpg",
        "/assets/images/wiczlino-2/diary/2024-02/mini_IMG_20240226_071847.jpg",
        "/assets/images/wiczlino-2/diary/2024-02/mini_IMG_20240226_071848.jpg",
      ],
    ],
    alts: [],
  },
  {
    title: "offer.janowo.diary.timeline.january",
    year: 2024,
    pics: [
      [
        "/assets/images/wiczlino-2/diary/2024-01/20240206_103609.jpg",
        "/assets/images/wiczlino-2/diary/2024-01/20240206_103644.jpg",
        "/assets/images/wiczlino-2/diary/2024-01/20240206_103714.jpg",
        "/assets/images/wiczlino-2/diary/2024-01/20240206_103720.jpg",
        "/assets/images/wiczlino-2/diary/2024-01/20240206_103726.jpg",
        "/assets/images/wiczlino-2/diary/2024-01/20240206_103807.jpg",
        "/assets/images/wiczlino-2/diary/2024-01/20240206_103810.jpg",
        "/assets/images/wiczlino-2/diary/2024-01/20240206_103813.jpg",
        "/assets/images/wiczlino-2/diary/2024-01/20240206_103815.jpg",
        "/assets/images/wiczlino-2/diary/2024-01/20240206_103919.jpg",
        "/assets/images/wiczlino-2/diary/2024-01/20240206_103921.jpg",
        "/assets/images/wiczlino-2/diary/2024-01/20240206_103928.jpg",
        "/assets/images/wiczlino-2/diary/2024-01/20240206_103930.jpg",
        "/assets/images/wiczlino-2/diary/2024-01/20240206_103947.jpg",
        "/assets/images/wiczlino-2/diary/2024-01/20240206_104002.jpg",
        "/assets/images/wiczlino-2/diary/2024-01/20240206_104003.jpg",
        "/assets/images/wiczlino-2/diary/2024-01/20240206_104040.jpg",
        "/assets/images/wiczlino-2/diary/2024-01/20240206_104042.jpg",
        "/assets/images/wiczlino-2/diary/2024-01/20240206_104047.jpg",
        "/assets/images/wiczlino-2/diary/2024-01/20240206_104049.jpg",
      ],
      [
        "/assets/images/wiczlino-2/diary/2024-01/mini_20240206_103609.jpg",
        "/assets/images/wiczlino-2/diary/2024-01/mini_20240206_103644.jpg",
        "/assets/images/wiczlino-2/diary/2024-01/mini_20240206_103714.jpg",
        "/assets/images/wiczlino-2/diary/2024-01/mini_20240206_103720.jpg",
        "/assets/images/wiczlino-2/diary/2024-01/mini_20240206_103726.jpg",
        "/assets/images/wiczlino-2/diary/2024-01/mini_20240206_103807.jpg",
        "/assets/images/wiczlino-2/diary/2024-01/mini_20240206_103810.jpg",
        "/assets/images/wiczlino-2/diary/2024-01/mini_20240206_103813.jpg",
        "/assets/images/wiczlino-2/diary/2024-01/mini_20240206_103815.jpg",
        "/assets/images/wiczlino-2/diary/2024-01/mini_20240206_103919.jpg",
        "/assets/images/wiczlino-2/diary/2024-01/mini_20240206_103921.jpg",
        "/assets/images/wiczlino-2/diary/2024-01/mini_20240206_103928.jpg",
        "/assets/images/wiczlino-2/diary/2024-01/mini_20240206_103930.jpg",
        "/assets/images/wiczlino-2/diary/2024-01/mini_20240206_103947.jpg",
        "/assets/images/wiczlino-2/diary/2024-01/mini_20240206_104002.jpg",
        "/assets/images/wiczlino-2/diary/2024-01/mini_20240206_104003.jpg",
        "/assets/images/wiczlino-2/diary/2024-01/mini_20240206_104040.jpg",
        "/assets/images/wiczlino-2/diary/2024-01/mini_20240206_104042.jpg",
        "/assets/images/wiczlino-2/diary/2024-01/mini_20240206_104047.jpg",
        "/assets/images/wiczlino-2/diary/2024-01/mini_20240206_104049.jpg",
      ],
    ],
    alts: [],
  },
  {
    title: "offer.janowo.diary.timeline.december",
    year: 2023,
    pics: [
      [
        "/assets/images/wiczlino-2/diary/2023-12/WILL3.jpg",
        "/assets/images/wiczlino-2/diary/2023-12/20240112_124724.jpg",
        "/assets/images/wiczlino-2/diary/2023-12/20240112_124725.jpg",
        "/assets/images/wiczlino-2/diary/2023-12/20240112_124755.jpg",
        "/assets/images/wiczlino-2/diary/2023-12/20240112_124808.jpg",
        "/assets/images/wiczlino-2/diary/2023-12/20240112_124946.jpg",
        "/assets/images/wiczlino-2/diary/2023-12/20240112_124949.jpg",
        "/assets/images/wiczlino-2/diary/2023-12/20240112_125916.jpg",
        "/assets/images/wiczlino-2/diary/2023-12/20240112_125945.jpg",
      ],
      [
        "/assets/images/wiczlino-2/diary/2023-12/mini_WILL3.jpg",
        "/assets/images/wiczlino-2/diary/2023-12/mini_20240112_124724.jpg",
        "/assets/images/wiczlino-2/diary/2023-12/mini_20240112_124725.jpg",
        "/assets/images/wiczlino-2/diary/2023-12/mini_20240112_124755.jpg",
        "/assets/images/wiczlino-2/diary/2023-12/mini_20240112_124808.jpg",
        "/assets/images/wiczlino-2/diary/2023-12/mini_20240112_124946.jpg",
        "/assets/images/wiczlino-2/diary/2023-12/mini_20240112_124949.jpg",
        "/assets/images/wiczlino-2/diary/2023-12/mini_20240112_125916.jpg",
        "/assets/images/wiczlino-2/diary/2023-12/mini_20240112_125945.jpg",
      ],
    ],
    alts: [],
  },
  {
    title: "offer.janowo.diary.timeline.november",
    year: 2023,
    pics: [
      [
        "/assets/images/wiczlino-2/diary/2023-11/20231129_100505.jpg",
        "/assets/images/wiczlino-2/diary/2023-11/20231129_100537.jpg",
        "/assets/images/wiczlino-2/diary/2023-11/20231129_100605.jpg",
        "/assets/images/wiczlino-2/diary/2023-11/20231129_101036.jpg",
        "/assets/images/wiczlino-2/diary/2023-11/20231129_101037.jpg",
        "/assets/images/wiczlino-2/diary/2023-11/20231129_101119.jpg",
        "/assets/images/wiczlino-2/diary/2023-11/20231129_101138.jpg",
        "/assets/images/wiczlino-2/diary/2023-11/20231129_101159.jpg",
        "/assets/images/wiczlino-2/diary/2023-11/20231129_101207.jpg",
      ],
      [
        "/assets/images/wiczlino-2/diary/2023-11/mini_20231129_100505.jpg",
        "/assets/images/wiczlino-2/diary/2023-11/mini_20231129_100537.jpg",
        "/assets/images/wiczlino-2/diary/2023-11/mini_20231129_100605.jpg",
        "/assets/images/wiczlino-2/diary/2023-11/mini_20231129_101036.jpg",
        "/assets/images/wiczlino-2/diary/2023-11/mini_20231129_101037.jpg",
        "/assets/images/wiczlino-2/diary/2023-11/mini_20231129_101119.jpg",
        "/assets/images/wiczlino-2/diary/2023-11/mini_20231129_101138.jpg",
        "/assets/images/wiczlino-2/diary/2023-11/mini_20231129_101159.jpg",
        "/assets/images/wiczlino-2/diary/2023-11/mini_20231129_101207.jpg",
      ],
    ],
    alts: [],
  },
  {
    title: "offer.janowo.diary.timeline.october",
    year: 2023,
    pics: [
      [
        "/assets/images/wiczlino-2/diary/2023-10/IMG_20231017_082038.jpg",
        "/assets/images/wiczlino-2/diary/2023-10/IMG_20231030_084035.jpg",
        "/assets/images/wiczlino-2/diary/2023-10/IMG_20231030_123618.jpg",
        "/assets/images/wiczlino-2/diary/2023-10/IMG_20231102_092431.jpg",
        "/assets/images/wiczlino-2/diary/2023-10/IMG_20231102_095421.jpg",
        "/assets/images/wiczlino-2/diary/2023-10/IMG_20231102_110819.jpg",
        "/assets/images/wiczlino-2/diary/2023-10/IMG_20231102_110830.jpg",
        "/assets/images/wiczlino-2/diary/2023-10/IMG_20231102_110901.jpg",
      ],
      [
        "/assets/images/wiczlino-2/diary/2023-10/mini_IMG_20231017_082038.jpg",
        "/assets/images/wiczlino-2/diary/2023-10/mini_IMG_20231030_084035.jpg",
        "/assets/images/wiczlino-2/diary/2023-10/mini_IMG_20231030_123618.jpg",
        "/assets/images/wiczlino-2/diary/2023-10/mini_IMG_20231102_092431.jpg",
        "/assets/images/wiczlino-2/diary/2023-10/mini_IMG_20231102_095421.jpg",
        "/assets/images/wiczlino-2/diary/2023-10/mini_IMG_20231102_110819.jpg",
        "/assets/images/wiczlino-2/diary/2023-10/mini_IMG_20231102_110830.jpg",
        "/assets/images/wiczlino-2/diary/2023-10/mini_IMG_20231102_110901.jpg",
      ],
    ],
    alts: [],
  },
  {
    title: "offer.janowo.diary.timeline.september",
    year: 2023,
    pics: [
      [
        "/assets/images/wiczlino-2/diary/2023-09/IMG_20231003_081109.jpg",
        "/assets/images/wiczlino-2/diary/2023-09/IMG_20231016_115223.jpg",
        "/assets/images/wiczlino-2/diary/2023-09/IMG_20231016_115237.jpg",
        "/assets/images/wiczlino-2/diary/2023-09/IMG_20231016_115419.jpg",
        "/assets/images/wiczlino-2/diary/2023-09/IMG_20231016_115612.jpg",
        "/assets/images/wiczlino-2/diary/2023-09/IMG_20231016_115654.jpg",
        "/assets/images/wiczlino-2/diary/2023-09/IMG_20231016_115752.jpg",
        "/assets/images/wiczlino-2/diary/2023-09/IMG_20231016_115805.jpg",
        "/assets/images/wiczlino-2/diary/2023-09/IMG_20231016_115908.jpg",
        "/assets/images/wiczlino-2/diary/2023-09/IMG_20231016_134241.jpg",
        "/assets/images/wiczlino-2/diary/2023-09/IMG_20231016_134433.jpg",
        "/assets/images/wiczlino-2/diary/2023-09/IMG_20231016_134749.jpg",
        "/assets/images/wiczlino-2/diary/2023-09/IMG_20231016_151444.jpg",
        "/assets/images/wiczlino-2/diary/2023-09/IMG_20231017_071309.jpg",
        "/assets/images/wiczlino-2/diary/2023-09/IMG_20231017_071338.jpg",
        "/assets/images/wiczlino-2/diary/2023-09/IMG_20231017_071451.jpg",
        "/assets/images/wiczlino-2/diary/2023-09/IMG_20231017_071609.jpg",
        "/assets/images/wiczlino-2/diary/2023-09/IMG_20231017_074443.jpg",
        "/assets/images/wiczlino-2/diary/2023-09/IMG_20231017_075041.jpg",
        "/assets/images/wiczlino-2/diary/2023-09/IMG_20231017_075102.jpg",
        "/assets/images/wiczlino-2/diary/2023-09/IMG_20231017_075307.jpg",
        "/assets/images/wiczlino-2/diary/2023-09/IMG_20231017_075836.jpg",
        "/assets/images/wiczlino-2/diary/2023-09/IMG_20231017_081329.jpg",
        "/assets/images/wiczlino-2/diary/2023-09/IMG_20231017_081558.jpg",
        "/assets/images/wiczlino-2/diary/2023-09/IMG_20231017_113030.jpg",
        "/assets/images/wiczlino-2/diary/2023-09/IMG_20231017_113112.jpg",
        "/assets/images/wiczlino-2/diary/2023-09/IMG_20231017_113301.jpg",
        "/assets/images/wiczlino-2/diary/2023-09/IMG_20231017_113400.jpg",
        "/assets/images/wiczlino-2/diary/2023-09/IMG_20231017_113946.jpg",
        "/assets/images/wiczlino-2/diary/2023-09/IMG_20231017_114515.jpg",
        "/assets/images/wiczlino-2/diary/2023-09/IMG_20231017_114725.jpg",
        "/assets/images/wiczlino-2/diary/2023-09/IMG_20231017_114733.jpg",
        "/assets/images/wiczlino-2/diary/2023-09/IMG_20231017_114818.jpg",
        "/assets/images/wiczlino-2/diary/2023-09/IMG_20231017_115044.jpg",

        "/assets/images/wiczlino-2/diary/2023-09/20230912_130646.jpg",
        "/assets/images/wiczlino-2/diary/2023-09/20230912_130738.jpg",
        "/assets/images/wiczlino-2/diary/2023-09/20230912_130816.jpg",
        "/assets/images/wiczlino-2/diary/2023-09/20230912_131255.jpg",
        "/assets/images/wiczlino-2/diary/2023-09/20230912_131305.jpg",
        "/assets/images/wiczlino-2/diary/2023-09/20230912_131349.jpg",
        "/assets/images/wiczlino-2/diary/2023-09/20230912_131649.jpg",
        "/assets/images/wiczlino-2/diary/2023-09/20230912_131741.jpg",
        "/assets/images/wiczlino-2/diary/2023-09/20230912_131759.jpg",
        "/assets/images/wiczlino-2/diary/2023-09/20230912_131810.jpg",
        "/assets/images/wiczlino-2/diary/2023-09/20230912_131858.jpg",
        "/assets/images/wiczlino-2/diary/2023-09/20230912_131915.jpg",
        "/assets/images/wiczlino-2/diary/2023-09/20230912_131953.jpg",
        "/assets/images/wiczlino-2/diary/2023-09/20230912_132020.jpg",
        "/assets/images/wiczlino-2/diary/2023-09/20230912_132118.jpg",
        "/assets/images/wiczlino-2/diary/2023-09/20230912_132228.jpg",
        "/assets/images/wiczlino-2/diary/2023-09/20230912_132302.jpg",
        "/assets/images/wiczlino-2/diary/2023-09/20230912_132510.jpg",
      ],
      [
        "/assets/images/wiczlino-2/diary/2023-09/mini_IMG_20231003_081109.jpg",
        "/assets/images/wiczlino-2/diary/2023-09/mini_IMG_20231016_115223.jpg",
        "/assets/images/wiczlino-2/diary/2023-09/mini_IMG_20231016_115237.jpg",
        "/assets/images/wiczlino-2/diary/2023-09/mini_IMG_20231016_115419.jpg",
        "/assets/images/wiczlino-2/diary/2023-09/mini_IMG_20231016_115612.jpg",
        "/assets/images/wiczlino-2/diary/2023-09/mini_IMG_20231016_115654.jpg",
        "/assets/images/wiczlino-2/diary/2023-09/mini_IMG_20231016_115752.jpg",
        "/assets/images/wiczlino-2/diary/2023-09/mini_IMG_20231016_115805.jpg",
        "/assets/images/wiczlino-2/diary/2023-09/mini_IMG_20231016_115908.jpg",
        "/assets/images/wiczlino-2/diary/2023-09/mini_IMG_20231016_134241.jpg",
        "/assets/images/wiczlino-2/diary/2023-09/mini_IMG_20231016_134433.jpg",
        "/assets/images/wiczlino-2/diary/2023-09/mini_IMG_20231016_134749.jpg",
        "/assets/images/wiczlino-2/diary/2023-09/mini_IMG_20231016_151444.jpg",
        "/assets/images/wiczlino-2/diary/2023-09/mini_IMG_20231017_071309.jpg",
        "/assets/images/wiczlino-2/diary/2023-09/mini_IMG_20231017_071338.jpg",
        "/assets/images/wiczlino-2/diary/2023-09/mini_IMG_20231017_071451.jpg",
        "/assets/images/wiczlino-2/diary/2023-09/mini_IMG_20231017_071609.jpg",
        "/assets/images/wiczlino-2/diary/2023-09/mini_IMG_20231017_074443.jpg",
        "/assets/images/wiczlino-2/diary/2023-09/mini_IMG_20231017_075041.jpg",
        "/assets/images/wiczlino-2/diary/2023-09/mini_IMG_20231017_075102.jpg",
        "/assets/images/wiczlino-2/diary/2023-09/mini_IMG_20231017_075307.jpg",
        "/assets/images/wiczlino-2/diary/2023-09/mini_IMG_20231017_075836.jpg",
        "/assets/images/wiczlino-2/diary/2023-09/mini_IMG_20231017_081329.jpg",
        "/assets/images/wiczlino-2/diary/2023-09/mini_IMG_20231017_081558.jpg",
        "/assets/images/wiczlino-2/diary/2023-09/mini_IMG_20231017_113030.jpg",
        "/assets/images/wiczlino-2/diary/2023-09/mini_IMG_20231017_113112.jpg",
        "/assets/images/wiczlino-2/diary/2023-09/mini_IMG_20231017_113301.jpg",
        "/assets/images/wiczlino-2/diary/2023-09/mini_IMG_20231017_113400.jpg",
        "/assets/images/wiczlino-2/diary/2023-09/mini_IMG_20231017_113946.jpg",
        "/assets/images/wiczlino-2/diary/2023-09/mini_IMG_20231017_114515.jpg",
        "/assets/images/wiczlino-2/diary/2023-09/mini_IMG_20231017_114725.jpg",
        "/assets/images/wiczlino-2/diary/2023-09/mini_IMG_20231017_114733.jpg",
        "/assets/images/wiczlino-2/diary/2023-09/mini_IMG_20231017_114818.jpg",
        "/assets/images/wiczlino-2/diary/2023-09/mini_IMG_20231017_115044.jpg",

        "/assets/images/wiczlino-2/diary/2023-09/mini_20230912_130646.jpg",
        "/assets/images/wiczlino-2/diary/2023-09/mini_20230912_130738.jpg",
        "/assets/images/wiczlino-2/diary/2023-09/mini_20230912_130816.jpg",
        "/assets/images/wiczlino-2/diary/2023-09/mini_20230912_131255.jpg",
        "/assets/images/wiczlino-2/diary/2023-09/mini_20230912_131305.jpg",
        "/assets/images/wiczlino-2/diary/2023-09/mini_20230912_131349.jpg",
        "/assets/images/wiczlino-2/diary/2023-09/mini_20230912_131649.jpg",
        "/assets/images/wiczlino-2/diary/2023-09/mini_20230912_131741.jpg",
        "/assets/images/wiczlino-2/diary/2023-09/mini_20230912_131759.jpg",
        "/assets/images/wiczlino-2/diary/2023-09/mini_20230912_131810.jpg",
        "/assets/images/wiczlino-2/diary/2023-09/mini_20230912_131858.jpg",
        "/assets/images/wiczlino-2/diary/2023-09/mini_20230912_131915.jpg",
        "/assets/images/wiczlino-2/diary/2023-09/mini_20230912_131953.jpg",
        "/assets/images/wiczlino-2/diary/2023-09/mini_20230912_132020.jpg",
        "/assets/images/wiczlino-2/diary/2023-09/mini_20230912_132118.jpg",
        "/assets/images/wiczlino-2/diary/2023-09/mini_20230912_132228.jpg",
        "/assets/images/wiczlino-2/diary/2023-09/mini_20230912_132302.jpg",
        "/assets/images/wiczlino-2/diary/2023-09/mini_20230912_132510.jpg",
      ],
    ],
    alts: [],
  },
  {
    title: "offer.janowo.diary.timeline.august",
    year: 2023,
    pics: [
      [
        "/assets/images/wiczlino-2/diary/2023-08/20230821_143855.jpg",
        "/assets/images/wiczlino-2/diary/2023-08/20230821_143928.jpg",
        "/assets/images/wiczlino-2/diary/2023-08/20230821_144027.jpg",
        "/assets/images/wiczlino-2/diary/2023-08/20230821_144044.jpg",
        "/assets/images/wiczlino-2/diary/2023-08/20230821_144057.jpg",
        "/assets/images/wiczlino-2/diary/2023-08/20230821_144118.jpg",
        "/assets/images/wiczlino-2/diary/2023-08/20230821_144145.jpg",
        "/assets/images/wiczlino-2/diary/2023-08/20230821_144156.jpg",
        "/assets/images/wiczlino-2/diary/2023-08/20230821_144215.jpg",
        "/assets/images/wiczlino-2/diary/2023-08/20230821_144227.jpg",
        "/assets/images/wiczlino-2/diary/2023-08/20230821_144452.jpg",
        "/assets/images/wiczlino-2/diary/2023-08/20230821_144507.jpg",
        "/assets/images/wiczlino-2/diary/2023-08/20230821_144539.jpg",
        "/assets/images/wiczlino-2/diary/2023-08/20230821_144559.jpg",
        "/assets/images/wiczlino-2/diary/2023-08/20230821_144618.jpg",
        "/assets/images/wiczlino-2/diary/2023-08/20230821_144629.jpg",
        "/assets/images/wiczlino-2/diary/2023-08/20230821_144649.jpg",
        "/assets/images/wiczlino-2/diary/2023-08/20230821_145019.jpg",
        "/assets/images/wiczlino-2/diary/2023-08/20230821_145031.jpg",
        "/assets/images/wiczlino-2/diary/2023-08/20230821_145054.jpg",
        "/assets/images/wiczlino-2/diary/2023-08/20230821_145059.jpg",
      ],
      [
        "/assets/images/wiczlino-2/diary/2023-08/mini_20230821_143855.jpg",
        "/assets/images/wiczlino-2/diary/2023-08/mini_20230821_143928.jpg",
        "/assets/images/wiczlino-2/diary/2023-08/mini_20230821_144027.jpg",
        "/assets/images/wiczlino-2/diary/2023-08/mini_20230821_144044.jpg",
        "/assets/images/wiczlino-2/diary/2023-08/mini_20230821_144057.jpg",
        "/assets/images/wiczlino-2/diary/2023-08/mini_20230821_144118.jpg",
        "/assets/images/wiczlino-2/diary/2023-08/mini_20230821_144145.jpg",
        "/assets/images/wiczlino-2/diary/2023-08/mini_20230821_144156.jpg",
        "/assets/images/wiczlino-2/diary/2023-08/mini_20230821_144215.jpg",
        "/assets/images/wiczlino-2/diary/2023-08/mini_20230821_144227.jpg",
        "/assets/images/wiczlino-2/diary/2023-08/mini_20230821_144452.jpg",
        "/assets/images/wiczlino-2/diary/2023-08/mini_20230821_144507.jpg",
        "/assets/images/wiczlino-2/diary/2023-08/mini_20230821_144539.jpg",
        "/assets/images/wiczlino-2/diary/2023-08/mini_20230821_144559.jpg",
        "/assets/images/wiczlino-2/diary/2023-08/mini_20230821_144618.jpg",
        "/assets/images/wiczlino-2/diary/2023-08/mini_20230821_144629.jpg",
        "/assets/images/wiczlino-2/diary/2023-08/mini_20230821_144649.jpg",
        "/assets/images/wiczlino-2/diary/2023-08/mini_20230821_145019.jpg",
        "/assets/images/wiczlino-2/diary/2023-08/mini_20230821_145031.jpg",
        "/assets/images/wiczlino-2/diary/2023-08/mini_20230821_145054.jpg",
        "/assets/images/wiczlino-2/diary/2023-08/mini_20230821_145059.jpg",
      ],
    ],
    alts: [],
  },
  {
    title: "offer.janowo.diary.timeline.july",
    year: 2023,
    pics: [
      [
        "/assets/images/wiczlino-2/diary/2023-07/20230727_093436.jpg",
        "/assets/images/wiczlino-2/diary/2023-07/20230727_093544.jpg",
        "/assets/images/wiczlino-2/diary/2023-07/20230727_093702.jpg",
        "/assets/images/wiczlino-2/diary/2023-07/20230727_093707.jpg",
        "/assets/images/wiczlino-2/diary/2023-07/20230727_093732.jpg",
        "/assets/images/wiczlino-2/diary/2023-07/20230727_093754.jpg",
        "/assets/images/wiczlino-2/diary/2023-07/20230727_093806.jpg",
        "/assets/images/wiczlino-2/diary/2023-07/20230727_093814.jpg",
        "/assets/images/wiczlino-2/diary/2023-07/20230727_093826.jpg",
        "/assets/images/wiczlino-2/diary/2023-07/20230727_093841.jpg",
        "/assets/images/wiczlino-2/diary/2023-07/20230727_093910.jpg",
        "/assets/images/wiczlino-2/diary/2023-07/20230727_093955.jpg",
        "/assets/images/wiczlino-2/diary/2023-07/20230727_094050.jpg",
        "/assets/images/wiczlino-2/diary/2023-07/20230727_094128.jpg",
        "/assets/images/wiczlino-2/diary/2023-07/20230727_094238.jpg",
        "/assets/images/wiczlino-2/diary/2023-07/20230727_094248.jpg",
        "/assets/images/wiczlino-2/diary/2023-07/20230727_094323.jpg",
        "/assets/images/wiczlino-2/diary/2023-07/20230727_094403.jpg",
        "/assets/images/wiczlino-2/diary/2023-07/20230727_094628.jpg",
        "/assets/images/wiczlino-2/diary/2023-07/20230727_094718.jpg",
        "/assets/images/wiczlino-2/diary/2023-07/20230727_095008.jpg",
        "/assets/images/wiczlino-2/diary/2023-07/20230727_095132.jpg",
      ],
      [
        "/assets/images/wiczlino-2/diary/2023-07/mini_20230727_093436.jpg",
        "/assets/images/wiczlino-2/diary/2023-07/mini_20230727_093544.jpg",
        "/assets/images/wiczlino-2/diary/2023-07/mini_20230727_093702.jpg",
        "/assets/images/wiczlino-2/diary/2023-07/mini_20230727_093707.jpg",
        "/assets/images/wiczlino-2/diary/2023-07/mini_20230727_093732.jpg",
        "/assets/images/wiczlino-2/diary/2023-07/mini_20230727_093754.jpg",
        "/assets/images/wiczlino-2/diary/2023-07/mini_20230727_093806.jpg",
        "/assets/images/wiczlino-2/diary/2023-07/mini_20230727_093814.jpg",
        "/assets/images/wiczlino-2/diary/2023-07/mini_20230727_093826.jpg",
        "/assets/images/wiczlino-2/diary/2023-07/mini_20230727_093841.jpg",
        "/assets/images/wiczlino-2/diary/2023-07/mini_20230727_093910.jpg",
        "/assets/images/wiczlino-2/diary/2023-07/mini_20230727_093955.jpg",
        "/assets/images/wiczlino-2/diary/2023-07/mini_20230727_094050.jpg",
        "/assets/images/wiczlino-2/diary/2023-07/mini_20230727_094128.jpg",
        "/assets/images/wiczlino-2/diary/2023-07/mini_20230727_094238.jpg",
        "/assets/images/wiczlino-2/diary/2023-07/mini_20230727_094248.jpg",
        "/assets/images/wiczlino-2/diary/2023-07/mini_20230727_094323.jpg",
        "/assets/images/wiczlino-2/diary/2023-07/mini_20230727_094403.jpg",
        "/assets/images/wiczlino-2/diary/2023-07/mini_20230727_094628.jpg",
        "/assets/images/wiczlino-2/diary/2023-07/mini_20230727_094718.jpg",
        "/assets/images/wiczlino-2/diary/2023-07/mini_20230727_095008.jpg",
        "/assets/images/wiczlino-2/diary/2023-07/mini_20230727_095132.jpg",
      ],
    ],
    alts: [],
  },
  {
    title: "offer.janowo.diary.timeline.june",
    year: 2023,
    pics: [
      [
        "/assets/images/wiczlino-2/diary/2023-06/20230628_114604.jpg",
        "/assets/images/wiczlino-2/diary/2023-06/20230628_114650.jpg",
        "/assets/images/wiczlino-2/diary/2023-06/20230628_114717.jpg",
        "/assets/images/wiczlino-2/diary/2023-06/20230628_114735.jpg",
        "/assets/images/wiczlino-2/diary/2023-06/20230628_114748.jpg",
        "/assets/images/wiczlino-2/diary/2023-06/20230628_115450.jpg",
        "/assets/images/wiczlino-2/diary/2023-06/20230628_115453.jpg",
        "/assets/images/wiczlino-2/diary/2023-06/20230628_115514.jpg",
        "/assets/images/wiczlino-2/diary/2023-06/20230628_115850.jpg",
        "/assets/images/wiczlino-2/diary/2023-06/20230628_120054.jpg",
        "/assets/images/wiczlino-2/diary/2023-06/20230628_120106.jpg",
        "/assets/images/wiczlino-2/diary/2023-06/20230628_120415.jpg",
        "/assets/images/wiczlino-2/diary/2023-06/20230628_121029.jpg",
        "/assets/images/wiczlino-2/diary/2023-06/20230628_121049.jpg",
        "/assets/images/wiczlino-2/diary/2023-06/20230628_121100.jpg",
        "/assets/images/wiczlino-2/diary/2023-06/20230628_121112.jpg",
        "/assets/images/wiczlino-2/diary/2023-06/20230628_121122.jpg",
      ],
      [
        "/assets/images/wiczlino-2/diary/2023-06/mini_20230628_114604.jpg",
        "/assets/images/wiczlino-2/diary/2023-06/mini_20230628_114650.jpg",
        "/assets/images/wiczlino-2/diary/2023-06/mini_20230628_114717.jpg",
        "/assets/images/wiczlino-2/diary/2023-06/mini_20230628_114735.jpg",
        "/assets/images/wiczlino-2/diary/2023-06/mini_20230628_114748.jpg",
        "/assets/images/wiczlino-2/diary/2023-06/mini_20230628_115450.jpg",
        "/assets/images/wiczlino-2/diary/2023-06/mini_20230628_115453.jpg",
        "/assets/images/wiczlino-2/diary/2023-06/mini_20230628_115514.jpg",
        "/assets/images/wiczlino-2/diary/2023-06/mini_20230628_115850.jpg",
        "/assets/images/wiczlino-2/diary/2023-06/mini_20230628_120054.jpg",
        "/assets/images/wiczlino-2/diary/2023-06/mini_20230628_120106.jpg",
        "/assets/images/wiczlino-2/diary/2023-06/mini_20230628_120415.jpg",
        "/assets/images/wiczlino-2/diary/2023-06/mini_20230628_121029.jpg",
        "/assets/images/wiczlino-2/diary/2023-06/mini_20230628_121049.jpg",
        "/assets/images/wiczlino-2/diary/2023-06/mini_20230628_121100.jpg",
        "/assets/images/wiczlino-2/diary/2023-06/mini_20230628_121112.jpg",
        "/assets/images/wiczlino-2/diary/2023-06/mini_20230628_121122.jpg",
      ],
    ],
    alts: [],
  },
  {
    title: "offer.janowo.diary.timeline.may",
    year: 2023,
    pics: [
      [
        "/assets/images/wiczlino-2/diary/2023-05/20230524_145843.jpg",
        "/assets/images/wiczlino-2/diary/2023-05/20230524_145849.jpg",
        "/assets/images/wiczlino-2/diary/2023-05/20230524_145923.jpg",
        "/assets/images/wiczlino-2/diary/2023-05/20230524_145929.jpg",
        "/assets/images/wiczlino-2/diary/2023-05/20230524_150026.jpg",
        "/assets/images/wiczlino-2/diary/2023-05/20230524_150058.jpg",
        "/assets/images/wiczlino-2/diary/2023-05/20230524_150107.jpg",
        "/assets/images/wiczlino-2/diary/2023-05/20230524_150126.jpg",
        "/assets/images/wiczlino-2/diary/2023-05/20230524_150136.jpg",
        "/assets/images/wiczlino-2/diary/2023-05/20230524_150157.jpg",
        "/assets/images/wiczlino-2/diary/2023-05/20230524_150201.jpg",
        "/assets/images/wiczlino-2/diary/2023-05/20230524_150304.jpg",
        "/assets/images/wiczlino-2/diary/2023-05/20230524_152651.jpg",
        "/assets/images/wiczlino-2/diary/2023-05/20230524_152714.jpg",
        "/assets/images/wiczlino-2/diary/2023-05/20230524_152731.jpg",
        "/assets/images/wiczlino-2/diary/2023-05/20230524_152743.jpg",
        "/assets/images/wiczlino-2/diary/2023-05/20230524_152751.jpg",
        "/assets/images/wiczlino-2/diary/2023-05/20230524_152759.jpg",
        "/assets/images/wiczlino-2/diary/2023-05/20230524_152856.jpg",
        "/assets/images/wiczlino-2/diary/2023-05/20230524_152905.jpg",
        "/assets/images/wiczlino-2/diary/2023-05/20230524_152912.jpg",
        "/assets/images/wiczlino-2/diary/2023-05/20230524_153002.jpg",
        "/assets/images/wiczlino-2/diary/2023-05/20230524_153013.jpg",
      ],
      [
        "/assets/images/wiczlino-2/diary/2023-05/mini_20230524_145843.jpg",
        "/assets/images/wiczlino-2/diary/2023-05/mini_20230524_145849.jpg",
        "/assets/images/wiczlino-2/diary/2023-05/mini_20230524_145923.jpg",
        "/assets/images/wiczlino-2/diary/2023-05/mini_20230524_145929.jpg",
        "/assets/images/wiczlino-2/diary/2023-05/mini_20230524_150026.jpg",
        "/assets/images/wiczlino-2/diary/2023-05/mini_20230524_150058.jpg",
        "/assets/images/wiczlino-2/diary/2023-05/mini_20230524_150107.jpg",
        "/assets/images/wiczlino-2/diary/2023-05/mini_20230524_150126.jpg",
        "/assets/images/wiczlino-2/diary/2023-05/mini_20230524_150136.jpg",
        "/assets/images/wiczlino-2/diary/2023-05/mini_20230524_150157.jpg",
        "/assets/images/wiczlino-2/diary/2023-05/mini_20230524_150201.jpg",
        "/assets/images/wiczlino-2/diary/2023-05/mini_20230524_150304.jpg",
        "/assets/images/wiczlino-2/diary/2023-05/mini_20230524_152651.jpg",
        "/assets/images/wiczlino-2/diary/2023-05/mini_20230524_152714.jpg",
        "/assets/images/wiczlino-2/diary/2023-05/mini_20230524_152731.jpg",
        "/assets/images/wiczlino-2/diary/2023-05/mini_20230524_152743.jpg",
        "/assets/images/wiczlino-2/diary/2023-05/mini_20230524_152751.jpg",
        "/assets/images/wiczlino-2/diary/2023-05/mini_20230524_152759.jpg",
        "/assets/images/wiczlino-2/diary/2023-05/mini_20230524_152856.jpg",
        "/assets/images/wiczlino-2/diary/2023-05/mini_20230524_152905.jpg",
        "/assets/images/wiczlino-2/diary/2023-05/mini_20230524_152912.jpg",
        "/assets/images/wiczlino-2/diary/2023-05/mini_20230524_153002.jpg",
        "/assets/images/wiczlino-2/diary/2023-05/mini_20230524_153013.jpg",
      ],
    ],
    alts: [],
  },
  {
    title: "offer.janowo.diary.timeline.april",
    year: 2023,
    pics: [
      [
        "/assets/images/wiczlino-2/diary/2023-04/20230405_143228.jpg",
        "/assets/images/wiczlino-2/diary/2023-04/20230405_143235.jpg",
        "/assets/images/wiczlino-2/diary/2023-04/20230405_143255.jpg",
        "/assets/images/wiczlino-2/diary/2023-04/20230405_143329.jpg",
        "/assets/images/wiczlino-2/diary/2023-04/20230405_143333.jpg",
        "/assets/images/wiczlino-2/diary/2023-04/20230405_143401.jpg",
        "/assets/images/wiczlino-2/diary/2023-04/20230405_144902.jpg",
        "/assets/images/wiczlino-2/diary/2023-04/20230405_144914.jpg",
        "/assets/images/wiczlino-2/diary/2023-04/20230405_145032.jpg",
        "/assets/images/wiczlino-2/diary/2023-04/20230405_145044.jpg",
        "/assets/images/wiczlino-2/diary/2023-04/20230405_145052.jpg",
        "/assets/images/wiczlino-2/diary/2023-04/20230405_145104.jpg",
        "/assets/images/wiczlino-2/diary/2023-04/20230405_145147.jpg",
      ],
      [
        "/assets/images/wiczlino-2/diary/2023-04/mini_20230405_143228.jpg",
        "/assets/images/wiczlino-2/diary/2023-04/mini_20230405_143235.jpg",
        "/assets/images/wiczlino-2/diary/2023-04/mini_20230405_143255.jpg",
        "/assets/images/wiczlino-2/diary/2023-04/mini_20230405_143329.jpg",
        "/assets/images/wiczlino-2/diary/2023-04/mini_20230405_143333.jpg",
        "/assets/images/wiczlino-2/diary/2023-04/mini_20230405_143401.jpg",
        "/assets/images/wiczlino-2/diary/2023-04/mini_20230405_144902.jpg",
        "/assets/images/wiczlino-2/diary/2023-04/mini_20230405_144914.jpg",
        "/assets/images/wiczlino-2/diary/2023-04/mini_20230405_145032.jpg",
        "/assets/images/wiczlino-2/diary/2023-04/mini_20230405_145044.jpg",
        "/assets/images/wiczlino-2/diary/2023-04/mini_20230405_145052.jpg",
        "/assets/images/wiczlino-2/diary/2023-04/mini_20230405_145104.jpg",
        "/assets/images/wiczlino-2/diary/2023-04/mini_20230405_145147.jpg",
      ],
    ],
    alts: [],
  },
  {
    title: "offer.janowo.diary.timeline.february",
    year: 2023,
    pics: [
      [
        "/assets/images/wiczlino-2/diary/2023-02/20230301_133030.jpg",
        "/assets/images/wiczlino-2/diary/2023-02/20230301_133052.jpg",
        "/assets/images/wiczlino-2/diary/2023-02/20230301_133100.jpg",
        "/assets/images/wiczlino-2/diary/2023-02/20230301_133108.jpg",
        "/assets/images/wiczlino-2/diary/2023-02/20230301_133137.jpg",
        "/assets/images/wiczlino-2/diary/2023-02/20230301_133157.jpg",
        "/assets/images/wiczlino-2/diary/2023-02/20230301_133208.jpg",
        "/assets/images/wiczlino-2/diary/2023-02/20230301_133219.jpg",
        "/assets/images/wiczlino-2/diary/2023-02/20230301_133222.jpg",
        "/assets/images/wiczlino-2/diary/2023-02/20230301_133228.jpg",
        "/assets/images/wiczlino-2/diary/2023-02/20230301_133246.jpg",
        "/assets/images/wiczlino-2/diary/2023-02/20230301_133252.jpg",
        "/assets/images/wiczlino-2/diary/2023-02/20230301_133306.jpg",
        "/assets/images/wiczlino-2/diary/2023-02/20230301_133311.jpg",
        "/assets/images/wiczlino-2/diary/2023-02/20230301_133330.jpg",
        "/assets/images/wiczlino-2/diary/2023-02/20230301_133524.jpg",
        "/assets/images/wiczlino-2/diary/2023-02/20230301_133536.jpg",
        "/assets/images/wiczlino-2/diary/2023-02/20230301_133542.jpg",
        "/assets/images/wiczlino-2/diary/2023-02/20230301_133604.jpg",
        "/assets/images/wiczlino-2/diary/2023-02/20230301_133616.jpg",
        "/assets/images/wiczlino-2/diary/2023-02/20230301_133743.jpg",
        "/assets/images/wiczlino-2/diary/2023-02/20230301_133849.jpg",
        "/assets/images/wiczlino-2/diary/2023-02/20230301_133851.jpg",
        "/assets/images/wiczlino-2/diary/2023-02/20230301_133929.jpg",
        "/assets/images/wiczlino-2/diary/2023-02/20230301_134001.jpg",
        "/assets/images/wiczlino-2/diary/2023-02/20230301_134007.jpg",
        "/assets/images/wiczlino-2/diary/2023-02/20230301_134059.jpg",
        "/assets/images/wiczlino-2/diary/2023-02/20230301_134107.jpg",
        "/assets/images/wiczlino-2/diary/2023-02/20230301_134119.jpg",
      ],
      [
        "/assets/images/wiczlino-2/diary/2023-02/mini_20230301_133030.jpg",
        "/assets/images/wiczlino-2/diary/2023-02/mini_20230301_133052.jpg",
        "/assets/images/wiczlino-2/diary/2023-02/mini_20230301_133100.jpg",
        "/assets/images/wiczlino-2/diary/2023-02/mini_20230301_133108.jpg",
        "/assets/images/wiczlino-2/diary/2023-02/mini_20230301_133137.jpg",
        "/assets/images/wiczlino-2/diary/2023-02/mini_20230301_133157.jpg",
        "/assets/images/wiczlino-2/diary/2023-02/mini_20230301_133208.jpg",
        "/assets/images/wiczlino-2/diary/2023-02/mini_20230301_133219.jpg",
        "/assets/images/wiczlino-2/diary/2023-02/mini_20230301_133222.jpg",
        "/assets/images/wiczlino-2/diary/2023-02/mini_20230301_133228.jpg",
        "/assets/images/wiczlino-2/diary/2023-02/mini_20230301_133246.jpg",
        "/assets/images/wiczlino-2/diary/2023-02/mini_20230301_133252.jpg",
        "/assets/images/wiczlino-2/diary/2023-02/mini_20230301_133306.jpg",
        "/assets/images/wiczlino-2/diary/2023-02/mini_20230301_133311.jpg",
        "/assets/images/wiczlino-2/diary/2023-02/mini_20230301_133330.jpg",
        "/assets/images/wiczlino-2/diary/2023-02/mini_20230301_133524.jpg",
        "/assets/images/wiczlino-2/diary/2023-02/mini_20230301_133536.jpg",
        "/assets/images/wiczlino-2/diary/2023-02/mini_20230301_133542.jpg",
        "/assets/images/wiczlino-2/diary/2023-02/mini_20230301_133604.jpg",
        "/assets/images/wiczlino-2/diary/2023-02/mini_20230301_133616.jpg",
        "/assets/images/wiczlino-2/diary/2023-02/mini_20230301_133743.jpg",
        "/assets/images/wiczlino-2/diary/2023-02/mini_20230301_133849.jpg",
        "/assets/images/wiczlino-2/diary/2023-02/mini_20230301_133851.jpg",
        "/assets/images/wiczlino-2/diary/2023-02/mini_20230301_133929.jpg",
        "/assets/images/wiczlino-2/diary/2023-02/mini_20230301_134001.jpg",
        "/assets/images/wiczlino-2/diary/2023-02/mini_20230301_134007.jpg",
        "/assets/images/wiczlino-2/diary/2023-02/mini_20230301_134059.jpg",
        "/assets/images/wiczlino-2/diary/2023-02/mini_20230301_134107.jpg",
        "/assets/images/wiczlino-2/diary/2023-02/mini_20230301_134119.jpg",
      ],
    ],
    alts: [],
  },
  {
    title: "offer.janowo.diary.timeline.january",
    year: 2023,
    pics: [
      [
        "/assets/images/wiczlino-2/diary/2023-01/20230131_110609.jpg",
        "/assets/images/wiczlino-2/diary/2023-01/20230131_110936.jpg",
        "/assets/images/wiczlino-2/diary/2023-01/20230131_111031.jpg",
        "/assets/images/wiczlino-2/diary/2023-01/20230131_111519.jpg",
        "/assets/images/wiczlino-2/diary/2023-01/20230131_111542.jpg",
        "/assets/images/wiczlino-2/diary/2023-01/20230131_111554.jpg",
        "/assets/images/wiczlino-2/diary/2023-01/20230131_111752.jpg",
        "/assets/images/wiczlino-2/diary/2023-01/20230131_111819.jpg",
        "/assets/images/wiczlino-2/diary/2023-01/20230131_111825.jpg",
        "/assets/images/wiczlino-2/diary/2023-01/20230131_111835.jpg",
        "/assets/images/wiczlino-2/diary/2023-01/20230131_111842.jpg",
        "/assets/images/wiczlino-2/diary/2023-01/20230131_111846.jpg",
        "/assets/images/wiczlino-2/diary/2023-01/20230131_111904.jpg",
        "/assets/images/wiczlino-2/diary/2023-01/20230131_111908.jpg",
        "/assets/images/wiczlino-2/diary/2023-01/20230131_111912.jpg",
        "/assets/images/wiczlino-2/diary/2023-01/20230131_111916.jpg",
        "/assets/images/wiczlino-2/diary/2023-01/20230131_111941.jpg",
        "/assets/images/wiczlino-2/diary/2023-01/20230131_111956.jpg",
        "/assets/images/wiczlino-2/diary/2023-01/20230131_111959.jpg",
        "/assets/images/wiczlino-2/diary/2023-01/20230131_112002.jpg",
        "/assets/images/wiczlino-2/diary/2023-01/20230131_112028.jpg",
        "/assets/images/wiczlino-2/diary/2023-01/20230131_112040.jpg",
      ],
      [
        "/assets/images/wiczlino-2/diary/2023-01/mini_20230131_110609.jpg",
        "/assets/images/wiczlino-2/diary/2023-01/mini_20230131_110936.jpg",
        "/assets/images/wiczlino-2/diary/2023-01/mini_20230131_111031.jpg",
        "/assets/images/wiczlino-2/diary/2023-01/mini_20230131_111519.jpg",
        "/assets/images/wiczlino-2/diary/2023-01/mini_20230131_111542.jpg",
        "/assets/images/wiczlino-2/diary/2023-01/mini_20230131_111554.jpg",
        "/assets/images/wiczlino-2/diary/2023-01/mini_20230131_111752.jpg",
        "/assets/images/wiczlino-2/diary/2023-01/mini_20230131_111819.jpg",
        "/assets/images/wiczlino-2/diary/2023-01/mini_20230131_111825.jpg",
        "/assets/images/wiczlino-2/diary/2023-01/mini_20230131_111835.jpg",
        "/assets/images/wiczlino-2/diary/2023-01/mini_20230131_111842.jpg",
        "/assets/images/wiczlino-2/diary/2023-01/mini_20230131_111846.jpg",
        "/assets/images/wiczlino-2/diary/2023-01/mini_20230131_111904.jpg",
        "/assets/images/wiczlino-2/diary/2023-01/mini_20230131_111908.jpg",
        "/assets/images/wiczlino-2/diary/2023-01/mini_20230131_111912.jpg",
        "/assets/images/wiczlino-2/diary/2023-01/mini_20230131_111916.jpg",
        "/assets/images/wiczlino-2/diary/2023-01/mini_20230131_111941.jpg",
        "/assets/images/wiczlino-2/diary/2023-01/mini_20230131_111956.jpg",
        "/assets/images/wiczlino-2/diary/2023-01/mini_20230131_111959.jpg",
        "/assets/images/wiczlino-2/diary/2023-01/mini_20230131_112002.jpg",
        "/assets/images/wiczlino-2/diary/2023-01/mini_20230131_112028.jpg",
        "/assets/images/wiczlino-2/diary/2023-01/mini_20230131_112040.jpg",
      ],
    ],
    alts: [],
  },
  {
    title: "offer.janowo.diary.timeline.december",
    year: 2022,
    pics: [
      [
        "/assets/images/wiczlino-2/diary/2022-12/20230120_135925.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_135954.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_140018.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_140029.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_140033.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_140059.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_140120.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_140125.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_140130.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_140137.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_140139.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_140201.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_140224.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_140227.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_140231.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_140259.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_140301.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_140306.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_140309.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_140351.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_140356.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_140409.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_140427.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_140434.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_140444.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_140505.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_140527.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_140537.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_140541.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_140550.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_140615.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_140618.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_140627.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_140642.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_140646.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_140649.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_140724.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_140727.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_140738.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_140741.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_140756.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_140758.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_140833.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_140844.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_140851.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_140914.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_140918.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_140943.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_141019.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_141022.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_141032.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_141047.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_141110.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_141112.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_141119.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_141122.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_141140.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_141143.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_141213.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_141216.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_141218.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_141220.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_141224.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_141226.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_141245.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_141304.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_141309.jpg",
      ],
      [
        "/assets/images/wiczlino-2/diary/2022-12/20230120_135925_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_135954_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_140018_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_140029_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_140033_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_140059_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_140120_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_140125_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_140130_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_140137_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_140139_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_140201_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_140224_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_140227_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_140231_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_140259_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_140301_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_140306_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_140309_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_140351_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_140356_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_140409_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_140427_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_140434_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_140444_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_140505_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_140527_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_140537_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_140541_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_140550_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_140615_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_140618_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_140627_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_140642_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_140646_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_140649_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_140724_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_140727_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_140738_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_140741_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_140756_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_140758_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_140833_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_140844_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_140851_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_140914_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_140918_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_140943_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_141019_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_141022_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_141032_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_141047_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_141110_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_141112_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_141119_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_141122_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_141140_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_141143_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_141213_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_141216_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_141218_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_141220_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_141224_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_141226_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_141245_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_141304_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-12/20230120_141309_mini.jpg",
      ],
    ],
    alts: [],
  },
  {
    title: "offer.janowo.diary.timeline.november",
    year: 2022,
    pics: [
      [
        "/assets/images/wiczlino-2/diary/2022-11/20221208_084631.jpg",
        "/assets/images/wiczlino-2/diary/2022-11/20221208_084644.jpg",
        "/assets/images/wiczlino-2/diary/2022-11/20221208_084657.jpg",
        "/assets/images/wiczlino-2/diary/2022-11/20221208_084700.jpg",
        "/assets/images/wiczlino-2/diary/2022-11/20221208_084704.jpg",
        "/assets/images/wiczlino-2/diary/2022-11/20221208_084720.jpg",
        "/assets/images/wiczlino-2/diary/2022-11/20221208_084722.jpg",
        "/assets/images/wiczlino-2/diary/2022-11/20221208_084739.jpg",
        "/assets/images/wiczlino-2/diary/2022-11/20221208_084741.jpg",
        "/assets/images/wiczlino-2/diary/2022-11/20221208_084745.jpg",
        "/assets/images/wiczlino-2/diary/2022-11/20221208_084809.jpg",
        "/assets/images/wiczlino-2/diary/2022-11/20221208_084820.jpg",
        "/assets/images/wiczlino-2/diary/2022-11/20221208_084827.jpg",
        "/assets/images/wiczlino-2/diary/2022-11/20221208_084831.jpg",
        "/assets/images/wiczlino-2/diary/2022-11/20221208_084859.jpg",
        "/assets/images/wiczlino-2/diary/2022-11/20221208_084902.jpg",
        "/assets/images/wiczlino-2/diary/2022-11/20221208_084916.jpg",
        "/assets/images/wiczlino-2/diary/2022-11/20221208_084929.jpg",
        "/assets/images/wiczlino-2/diary/2022-11/20221208_084933.jpg",
        "/assets/images/wiczlino-2/diary/2022-11/20221208_085015.jpg",
        "/assets/images/wiczlino-2/diary/2022-11/20221208_085035.jpg",
        "/assets/images/wiczlino-2/diary/2022-11/20221208_085037.jpg",
        "/assets/images/wiczlino-2/diary/2022-11/20221208_085059.jpg",
        "/assets/images/wiczlino-2/diary/2022-11/20221208_085101.jpg",
        "/assets/images/wiczlino-2/diary/2022-11/20221208_085343.jpg",
        "/assets/images/wiczlino-2/diary/2022-11/20221208_085352.jpg",
        "/assets/images/wiczlino-2/diary/2022-11/20221208_085412.jpg",
        "/assets/images/wiczlino-2/diary/2022-11/20221208_085416.jpg",
        "/assets/images/wiczlino-2/diary/2022-11/20221208_085428.jpg",
        "/assets/images/wiczlino-2/diary/2022-11/20221208_085450.jpg",
        "/assets/images/wiczlino-2/diary/2022-11/willbud-gdynia-dron-1.jpg",
        "/assets/images/wiczlino-2/diary/2022-11/willbud-gdynia-dron-10.jpg",
        "/assets/images/wiczlino-2/diary/2022-11/willbud-gdynia-dron-11.jpg",
        "/assets/images/wiczlino-2/diary/2022-11/willbud-gdynia-dron-12.jpg",
        "/assets/images/wiczlino-2/diary/2022-11/willbud-gdynia-dron-13.jpg",
        "/assets/images/wiczlino-2/diary/2022-11/willbud-gdynia-dron-14.jpg",
        "/assets/images/wiczlino-2/diary/2022-11/willbud-gdynia-dron-2.jpg",
        "/assets/images/wiczlino-2/diary/2022-11/willbud-gdynia-dron-3.jpg",
        "/assets/images/wiczlino-2/diary/2022-11/willbud-gdynia-dron-4.jpg",
        "/assets/images/wiczlino-2/diary/2022-11/willbud-gdynia-dron-5.jpg",
        "/assets/images/wiczlino-2/diary/2022-11/willbud-gdynia-dron-6.jpg",
        "/assets/images/wiczlino-2/diary/2022-11/willbud-gdynia-dron-7.jpg",
        "/assets/images/wiczlino-2/diary/2022-11/willbud-gdynia-dron-8.jpg",
        "/assets/images/wiczlino-2/diary/2022-11/willbud-gdynia-dron-9.jpg",
      ],
      [
        "/assets/images/wiczlino-2/diary/2022-11/20221208_084631_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-11/20221208_084644_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-11/20221208_084657_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-11/20221208_084700_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-11/20221208_084704_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-11/20221208_084720_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-11/20221208_084722_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-11/20221208_084739_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-11/20221208_084741_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-11/20221208_084745_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-11/20221208_084809_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-11/20221208_084820_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-11/20221208_084827_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-11/20221208_084831_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-11/20221208_084859_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-11/20221208_084902_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-11/20221208_084916_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-11/20221208_084929_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-11/20221208_084933_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-11/20221208_085015_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-11/20221208_085035_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-11/20221208_085037_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-11/20221208_085059_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-11/20221208_085101_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-11/20221208_085343_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-11/20221208_085352_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-11/20221208_085412_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-11/20221208_085416_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-11/20221208_085428_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-11/20221208_085450_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-11/willbud-gdynia-dron-1_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-11/willbud-gdynia-dron-10_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-11/willbud-gdynia-dron-11_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-11/willbud-gdynia-dron-12_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-11/willbud-gdynia-dron-13_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-11/willbud-gdynia-dron-14_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-11/willbud-gdynia-dron-2_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-11/willbud-gdynia-dron-3_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-11/willbud-gdynia-dron-4_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-11/willbud-gdynia-dron-5_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-11/willbud-gdynia-dron-6_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-11/willbud-gdynia-dron-7_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-11/willbud-gdynia-dron-8_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-11/willbud-gdynia-dron-9_mini.jpg",
      ],
    ],
    alts: [],
  },
  {
    title: "offer.janowo.diary.timeline.october",
    year: 2022,
    pics: [
      [
        "/assets/images/wiczlino-2/diary/2022-10/20221026_124754.jpg",
        "/assets/images/wiczlino-2/diary/2022-10/20221026_124818.jpg",
        "/assets/images/wiczlino-2/diary/2022-10/20221026_124837.jpg",
        "/assets/images/wiczlino-2/diary/2022-10/20221026_124931.jpg",
        "/assets/images/wiczlino-2/diary/2022-10/20221026_124935.jpg",
        "/assets/images/wiczlino-2/diary/2022-10/20221026_125001.jpg",
        "/assets/images/wiczlino-2/diary/2022-10/20221026_125012.jpg",
        "/assets/images/wiczlino-2/diary/2022-10/20221026_125023.jpg",
        "/assets/images/wiczlino-2/diary/2022-10/20221026_125036.jpg",
        "/assets/images/wiczlino-2/diary/2022-10/20221026_125103.jpg",
        "/assets/images/wiczlino-2/diary/2022-10/20221026_125117.jpg",
        "/assets/images/wiczlino-2/diary/2022-10/20221026_125121.jpg",
        "/assets/images/wiczlino-2/diary/2022-10/20221026_125135.jpg",
        "/assets/images/wiczlino-2/diary/2022-10/20221026_125146.jpg",
        "/assets/images/wiczlino-2/diary/2022-10/20221026_125216.jpg",
        "/assets/images/wiczlino-2/diary/2022-10/20221026_125224.jpg",
        "/assets/images/wiczlino-2/diary/2022-10/20221026_125232.jpg",
        "/assets/images/wiczlino-2/diary/2022-10/20221026_125300.jpg",
        "/assets/images/wiczlino-2/diary/2022-10/20221026_125307.jpg",
        "/assets/images/wiczlino-2/diary/2022-10/20221026_125333.jpg",
        "/assets/images/wiczlino-2/diary/2022-10/20221026_125405.jpg",
        "/assets/images/wiczlino-2/diary/2022-10/20221026_125407.jpg",
        "/assets/images/wiczlino-2/diary/2022-10/20221026_125412.jpg",
        "/assets/images/wiczlino-2/diary/2022-10/20221026_125443.jpg",
        "/assets/images/wiczlino-2/diary/2022-10/20221026_125501.jpg",
        "/assets/images/wiczlino-2/diary/2022-10/20221026_125503.jpg",
        "/assets/images/wiczlino-2/diary/2022-10/20221026_125506.jpg",
        "/assets/images/wiczlino-2/diary/2022-10/20221026_125512.jpg",
        "/assets/images/wiczlino-2/diary/2022-10/20221026_125606.jpg",
        "/assets/images/wiczlino-2/diary/2022-10/20221026_125643.jpg",
      ],
      [
        "/assets/images/wiczlino-2/diary/2022-10/20221026_124754_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-10/20221026_124818_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-10/20221026_124837_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-10/20221026_124931_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-10/20221026_124935_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-10/20221026_125001_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-10/20221026_125012_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-10/20221026_125023_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-10/20221026_125036_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-10/20221026_125103_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-10/20221026_125117_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-10/20221026_125121_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-10/20221026_125135_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-10/20221026_125146_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-10/20221026_125216_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-10/20221026_125224_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-10/20221026_125232_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-10/20221026_125300_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-10/20221026_125307_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-10/20221026_125333_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-10/20221026_125405_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-10/20221026_125407_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-10/20221026_125412_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-10/20221026_125443_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-10/20221026_125501_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-10/20221026_125503_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-10/20221026_125506_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-10/20221026_125512_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-10/20221026_125606_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-10/20221026_125643_mini.jpg",
      ],
    ],
    alts: [],
  },
  {
    title: "offer.janowo.diary.timeline.september",
    year: 2022,
    pics: [
      [
        "/assets/images/wiczlino-2/diary/2022-09/20221007_111326.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221007_111348.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221007_111405.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221007_111427.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221007_111439.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221007_111538.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221007_111555.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221007_111600.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221007_111609.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221007_111622.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221007_111649.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221007_111659.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221007_111718.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221007_111738.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221007_111804.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221007_111810.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221007_111831.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221007_111835.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221007_111839.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221007_111846.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221007_111926.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221007_112023.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221007_112030.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221007_112055.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221007_112110.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221007_112113.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221007_112132.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221007_112151.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221007_112242.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221007_112250.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221007_112255.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221007_112314.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221007_112338.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221007_112353.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221007_112423.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221007_112430.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221007_112433.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221007_112452.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221007_112454.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221007_112509.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221007_112543.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221007_112546.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221007_112549.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221013_131804.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221013_131838.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221013_131925.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221013_131935.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221013_131938.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221013_131944.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221013_132050.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221013_132114.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221013_132714.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221013_132717.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221013_132827.jpg",
      ],
      [
        "/assets/images/wiczlino-2/diary/2022-09/20221007_111326_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221007_111348_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221007_111405_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221007_111427_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221007_111439_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221007_111538_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221007_111555_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221007_111600_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221007_111609_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221007_111622_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221007_111649_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221007_111659_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221007_111718_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221007_111738_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221007_111804_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221007_111810_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221007_111831_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221007_111835_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221007_111839_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221007_111846_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221007_111926_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221007_112023_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221007_112030_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221007_112055_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221007_112110_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221007_112113_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221007_112132_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221007_112151_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221007_112242_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221007_112250_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221007_112255_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221007_112314_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221007_112338_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221007_112353_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221007_112423_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221007_112430_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221007_112433_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221007_112452_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221007_112454_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221007_112509_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221007_112543_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221007_112546_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221007_112549_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221013_131804_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221013_131838_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221013_131925_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221013_131935_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221013_131938_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221013_131944_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221013_132050_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221013_132114_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221013_132714_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221013_132717_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-09/20221013_132827_mini.jpg",
      ],
    ],
    alts: [],
  },
  {
    title: "offer.janowo.diary.timeline.august",
    year: 2022,
    pics: [
      [
        "/assets/images/wiczlino-2/diary/2022-08/20220914_120347.jpg",
        "/assets/images/wiczlino-2/diary/2022-08/20220914_120350.jpg",
        "/assets/images/wiczlino-2/diary/2022-08/20220914_120415.jpg",
        "/assets/images/wiczlino-2/diary/2022-08/20220914_120418.jpg",
        "/assets/images/wiczlino-2/diary/2022-08/20220914_120455.jpg",
        "/assets/images/wiczlino-2/diary/2022-08/20220914_120516.jpg",
        "/assets/images/wiczlino-2/diary/2022-08/20220914_120522.jpg",
        "/assets/images/wiczlino-2/diary/2022-08/20220914_120543.jpg",
        "/assets/images/wiczlino-2/diary/2022-08/20220914_120547.jpg",
        "/assets/images/wiczlino-2/diary/2022-08/20220914_120658.jpg",
        "/assets/images/wiczlino-2/diary/2022-08/20220914_120709.jpg",
        "/assets/images/wiczlino-2/diary/2022-08/20220914_120743.jpg",
        "/assets/images/wiczlino-2/diary/2022-08/20220914_120806.jpg",
        "/assets/images/wiczlino-2/diary/2022-08/20220914_120810.jpg",
        "/assets/images/wiczlino-2/diary/2022-08/20220914_120849.jpg",
        "/assets/images/wiczlino-2/diary/2022-08/20220914_120854.jpg",
        "/assets/images/wiczlino-2/diary/2022-08/20220914_120909.jpg",
        "/assets/images/wiczlino-2/diary/2022-08/20220914_120942.jpg",
        "/assets/images/wiczlino-2/diary/2022-08/20220914_120947.jpg",
        "/assets/images/wiczlino-2/diary/2022-08/20220914_120949.jpg",
        "/assets/images/wiczlino-2/diary/2022-08/20220914_121045.jpg",
        "/assets/images/wiczlino-2/diary/2022-08/20220914_121053.jpg",
        "/assets/images/wiczlino-2/diary/2022-08/20220914_121123.jpg",
        "/assets/images/wiczlino-2/diary/2022-08/20220914_121200.jpg",
        "/assets/images/wiczlino-2/diary/2022-08/20220914_121233.jpg",
        "/assets/images/wiczlino-2/diary/2022-08/20220914_121240.jpg",
        "/assets/images/wiczlino-2/diary/2022-08/20220914_121320.jpg",
        "/assets/images/wiczlino-2/diary/2022-08/20220914_121351.jpg",
        "/assets/images/wiczlino-2/diary/2022-08/20220914_121354.jpg",
        "/assets/images/wiczlino-2/diary/2022-08/20220914_121357.jpg",
        "/assets/images/wiczlino-2/diary/2022-08/20220914_121426.jpg",
        "/assets/images/wiczlino-2/diary/2022-08/20220914_121441.jpg",
        "/assets/images/wiczlino-2/diary/2022-08/20220914_121508.jpg",
        "/assets/images/wiczlino-2/diary/2022-08/20220914_121730.jpg",
        "/assets/images/wiczlino-2/diary/2022-08/20220914_122014.jpg",
        "/assets/images/wiczlino-2/diary/2022-08/20220914_122018.jpg",
      ],
      [
        "/assets/images/wiczlino-2/diary/2022-08/20220914_120347_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-08/20220914_120350_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-08/20220914_120415_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-08/20220914_120418_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-08/20220914_120455_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-08/20220914_120516_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-08/20220914_120522_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-08/20220914_120543_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-08/20220914_120547_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-08/20220914_120658_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-08/20220914_120709_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-08/20220914_120743_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-08/20220914_120806_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-08/20220914_120810_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-08/20220914_120849_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-08/20220914_120854_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-08/20220914_120909_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-08/20220914_120942_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-08/20220914_120947_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-08/20220914_120949_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-08/20220914_121045_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-08/20220914_121053_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-08/20220914_121123_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-08/20220914_121200_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-08/20220914_121233_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-08/20220914_121240_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-08/20220914_121320_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-08/20220914_121351_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-08/20220914_121354_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-08/20220914_121357_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-08/20220914_121426_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-08/20220914_121441_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-08/20220914_121508_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-08/20220914_121730_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-08/20220914_122014_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-08/20220914_122018_mini.jpg",
      ],
    ],
    alts: [],
  },
  {
    title: "offer.janowo.diary.timeline.july",
    year: 2022,
    pics: [
      [
        "/assets/images/wiczlino-2/diary/2022-07/20220805_112529.jpg",
        "/assets/images/wiczlino-2/diary/2022-07/20220805_112547.jpg",
        "/assets/images/wiczlino-2/diary/2022-07/20220805_113000.jpg",
        "/assets/images/wiczlino-2/diary/2022-07/20220805_113123.jpg",
        "/assets/images/wiczlino-2/diary/2022-07/20220805_113128.jpg",
        "/assets/images/wiczlino-2/diary/2022-07/20220805_113212.jpg",
        "/assets/images/wiczlino-2/diary/2022-07/20220805_113231.jpg",
        "/assets/images/wiczlino-2/diary/2022-07/20220805_113238.jpg",
        "/assets/images/wiczlino-2/diary/2022-07/20220805_113245.jpg",
        "/assets/images/wiczlino-2/diary/2022-07/20220805_113250.jpg",
        "/assets/images/wiczlino-2/diary/2022-07/20220805_113301.jpg",
        "/assets/images/wiczlino-2/diary/2022-07/20220805_113325.jpg",
        "/assets/images/wiczlino-2/diary/2022-07/20220805_113400.jpg",
        "/assets/images/wiczlino-2/diary/2022-07/20220805_113459.jpg",
        "/assets/images/wiczlino-2/diary/2022-07/20220805_113520.jpg",
        "/assets/images/wiczlino-2/diary/2022-07/20220805_113522.jpg",
        "/assets/images/wiczlino-2/diary/2022-07/20220805_113539.jpg",
        "/assets/images/wiczlino-2/diary/2022-07/20220805_113542.jpg",
        "/assets/images/wiczlino-2/diary/2022-07/20220805_113556.jpg",
        "/assets/images/wiczlino-2/diary/2022-07/20220805_113604.jpg",
        "/assets/images/wiczlino-2/diary/2022-07/20220805_113606.jpg",
        "/assets/images/wiczlino-2/diary/2022-07/20220805_113611.jpg",
        "/assets/images/wiczlino-2/diary/2022-07/20220805_113708.jpg",
        "/assets/images/wiczlino-2/diary/2022-07/20220805_113756.jpg",
        "/assets/images/wiczlino-2/diary/2022-07/20220805_113801.jpg",
        "/assets/images/wiczlino-2/diary/2022-07/20220805_113815.jpg",
        "/assets/images/wiczlino-2/diary/2022-07/20220805_113859.jpg",
        "/assets/images/wiczlino-2/diary/2022-07/20220805_113912.jpg",
        "/assets/images/wiczlino-2/diary/2022-07/20220805_113921.jpg",
        "/assets/images/wiczlino-2/diary/2022-07/20220805_113924.jpg",
        "/assets/images/wiczlino-2/diary/2022-07/20220805_113944.jpg",
        "/assets/images/wiczlino-2/diary/2022-07/20220805_114030.jpg",
        "/assets/images/wiczlino-2/diary/2022-07/20220805_114109.jpg",
        "/assets/images/wiczlino-2/diary/2022-07/20220805_114239.jpg",
        "/assets/images/wiczlino-2/diary/2022-07/20220805_115116.jpg",
        "/assets/images/wiczlino-2/diary/2022-07/20220805_115202.jpg",
      ],
      [
        "/assets/images/wiczlino-2/diary/2022-07/20220805_112529_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-07/20220805_112547_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-07/20220805_113000_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-07/20220805_113123_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-07/20220805_113128_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-07/20220805_113212_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-07/20220805_113231_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-07/20220805_113238_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-07/20220805_113245_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-07/20220805_113250_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-07/20220805_113301_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-07/20220805_113325_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-07/20220805_113400_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-07/20220805_113459_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-07/20220805_113520_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-07/20220805_113522_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-07/20220805_113539_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-07/20220805_113542_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-07/20220805_113556_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-07/20220805_113604_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-07/20220805_113606_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-07/20220805_113611_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-07/20220805_113708_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-07/20220805_113756_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-07/20220805_113801_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-07/20220805_113815_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-07/20220805_113859_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-07/20220805_113912_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-07/20220805_113921_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-07/20220805_113924_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-07/20220805_113944_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-07/20220805_114030_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-07/20220805_114109_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-07/20220805_114239_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-07/20220805_115116_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-07/20220805_115202_mini.jpg",
      ],
    ],
    alts: [],
  },
  {
    title: "offer.janowo.diary.timeline.june",
    year: 2022,
    pics: [
      [
        "/assets/images/wiczlino-2/diary/2022-06/20220706_140335.jpg",
        "/assets/images/wiczlino-2/diary/2022-06/20220706_140355.jpg",
        "/assets/images/wiczlino-2/diary/2022-06/20220706_140406.jpg",
        "/assets/images/wiczlino-2/diary/2022-06/20220706_140500.jpg",
        "/assets/images/wiczlino-2/diary/2022-06/20220706_140519.jpg",
        "/assets/images/wiczlino-2/diary/2022-06/20220706_140523.jpg",
        "/assets/images/wiczlino-2/diary/2022-06/20220706_140549.jpg",
        "/assets/images/wiczlino-2/diary/2022-06/20220706_140559.jpg",
        "/assets/images/wiczlino-2/diary/2022-06/20220706_140608.jpg",
        "/assets/images/wiczlino-2/diary/2022-06/20220706_140623.jpg",
        "/assets/images/wiczlino-2/diary/2022-06/20220706_140637.jpg",
        "/assets/images/wiczlino-2/diary/2022-06/20220706_140640.jpg",
        "/assets/images/wiczlino-2/diary/2022-06/20220706_140652.jpg",
        "/assets/images/wiczlino-2/diary/2022-06/20220706_140705.jpg",
        "/assets/images/wiczlino-2/diary/2022-06/20220706_140715.jpg",
        "/assets/images/wiczlino-2/diary/2022-06/20220706_140736.jpg",
        "/assets/images/wiczlino-2/diary/2022-06/20220706_140742.jpg",
        "/assets/images/wiczlino-2/diary/2022-06/20220706_140801.jpg",
        "/assets/images/wiczlino-2/diary/2022-06/20220706_140811.jpg",
        "/assets/images/wiczlino-2/diary/2022-06/20220706_140944.jpg",
        "/assets/images/wiczlino-2/diary/2022-06/20220706_140954.jpg",
        "/assets/images/wiczlino-2/diary/2022-06/20220706_141005.jpg",
        "/assets/images/wiczlino-2/diary/2022-06/20220706_141012.jpg",
        "/assets/images/wiczlino-2/diary/2022-06/20220706_141025.jpg",
        "/assets/images/wiczlino-2/diary/2022-06/20220706_141035.jpg",
        "/assets/images/wiczlino-2/diary/2022-06/20220706_141053.jpg",
        "/assets/images/wiczlino-2/diary/2022-06/20220706_141106.jpg",
        "/assets/images/wiczlino-2/diary/2022-06/20220706_141115.jpg",
        "/assets/images/wiczlino-2/diary/2022-06/20220706_141131.jpg",
        "/assets/images/wiczlino-2/diary/2022-06/20220706_141140.jpg",
        "/assets/images/wiczlino-2/diary/2022-06/20220706_141148.jpg",
        "/assets/images/wiczlino-2/diary/2022-06/20220706_141215.jpg",
        "/assets/images/wiczlino-2/diary/2022-06/20220706_141257.jpg",
        "/assets/images/wiczlino-2/diary/2022-06/20220706_141332.jpg",
        "/assets/images/wiczlino-2/diary/2022-06/20220706_141506.jpg",
      ],
      [
        "/assets/images/wiczlino-2/diary/2022-06/20220706_140335_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-06/20220706_140355_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-06/20220706_140406_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-06/20220706_140500_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-06/20220706_140519_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-06/20220706_140523_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-06/20220706_140549_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-06/20220706_140559_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-06/20220706_140608_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-06/20220706_140623_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-06/20220706_140637_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-06/20220706_140640_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-06/20220706_140652_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-06/20220706_140705_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-06/20220706_140715_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-06/20220706_140736_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-06/20220706_140742_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-06/20220706_140801_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-06/20220706_140811_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-06/20220706_140944_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-06/20220706_140954_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-06/20220706_141005_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-06/20220706_141012_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-06/20220706_141025_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-06/20220706_141035_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-06/20220706_141053_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-06/20220706_141106_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-06/20220706_141115_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-06/20220706_141131_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-06/20220706_141140_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-06/20220706_141148_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-06/20220706_141215_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-06/20220706_141257_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-06/20220706_141332_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-06/20220706_141506_mini.jpg",
      ],
    ],
    alts: [],
  },
  {
    title: "offer.janowo.diary.timeline.may",
    year: 2022,
    pics: [
      [
        "/assets/images/wiczlino-2/diary/2022-05/20220607_135259.jpg",
        "/assets/images/wiczlino-2/diary/2022-05/20220607_135311.jpg",
        "/assets/images/wiczlino-2/diary/2022-05/20220607_135332.jpg",
        "/assets/images/wiczlino-2/diary/2022-05/20220607_135349.jpg",
        "/assets/images/wiczlino-2/diary/2022-05/20220607_135404.jpg",
        "/assets/images/wiczlino-2/diary/2022-05/20220607_135420.jpg",
        "/assets/images/wiczlino-2/diary/2022-05/20220607_135442.jpg",
        "/assets/images/wiczlino-2/diary/2022-05/20220607_135505.jpg",
        "/assets/images/wiczlino-2/diary/2022-05/20220607_135537.jpg",
        "/assets/images/wiczlino-2/diary/2022-05/20220607_135557.jpg",
        "/assets/images/wiczlino-2/diary/2022-05/20220607_135602.jpg",
        "/assets/images/wiczlino-2/diary/2022-05/20220607_135626.jpg",
        "/assets/images/wiczlino-2/diary/2022-05/20220607_135642.jpg",
        "/assets/images/wiczlino-2/diary/2022-05/20220607_135748.jpg",
        "/assets/images/wiczlino-2/diary/2022-05/20220607_135755.jpg",
        "/assets/images/wiczlino-2/diary/2022-05/20220607_135810.jpg",
        "/assets/images/wiczlino-2/diary/2022-05/20220607_135840.jpg",
        "/assets/images/wiczlino-2/diary/2022-05/20220607_135858.jpg",
        "/assets/images/wiczlino-2/diary/2022-05/20220607_135931.jpg",
        "/assets/images/wiczlino-2/diary/2022-05/20220607_135952.jpg",
        "/assets/images/wiczlino-2/diary/2022-05/20220607_140038.jpg",
        "/assets/images/wiczlino-2/diary/2022-05/20220607_140040.jpg",
        "/assets/images/wiczlino-2/diary/2022-05/20220607_140124.jpg",
      ],
      [
        "/assets/images/wiczlino-2/diary/2022-05/20220607_135259_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-05/20220607_135311_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-05/20220607_135332_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-05/20220607_135349_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-05/20220607_135404_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-05/20220607_135420_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-05/20220607_135442_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-05/20220607_135505_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-05/20220607_135537_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-05/20220607_135557_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-05/20220607_135602_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-05/20220607_135626_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-05/20220607_135642_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-05/20220607_135748_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-05/20220607_135755_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-05/20220607_135810_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-05/20220607_135840_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-05/20220607_135858_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-05/20220607_135931_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-05/20220607_135952_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-05/20220607_140038_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-05/20220607_140040_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-05/20220607_140124_mini.jpg",
      ],
    ],
    alts: [],
  },

  {
    title: "offer.janowo.diary.timeline.april",
    year: 2022,
    pics: [
      [
        "/assets/images/wiczlino-2/diary/2022-05/20220519_132914.jpg",
        "/assets/images/wiczlino-2/diary/2022-04/20220519_133002.jpg",
        "/assets/images/wiczlino-2/diary/2022-04/20220519_133011.jpg",
        "/assets/images/wiczlino-2/diary/2022-04/20220519_133028.jpg",
        "/assets/images/wiczlino-2/diary/2022-04/20220519_133042.jpg",
        "/assets/images/wiczlino-2/diary/2022-04/20220519_133126.jpg",
        "/assets/images/wiczlino-2/diary/2022-04/20220519_133146.jpg",
        "/assets/images/wiczlino-2/diary/2022-04/20220519_133149.jpg",
        "/assets/images/wiczlino-2/diary/2022-04/20220519_133203.jpg",
        "/assets/images/wiczlino-2/diary/2022-04/20220519_133215.jpg",
        "/assets/images/wiczlino-2/diary/2022-04/20220519_133223.jpg",
        "/assets/images/wiczlino-2/diary/2022-04/20220519_133238.jpg",
        "/assets/images/wiczlino-2/diary/2022-04/20220519_133258.jpg",
        "/assets/images/wiczlino-2/diary/2022-04/20220519_133302.jpg",
        "/assets/images/wiczlino-2/diary/2022-04/20220519_133314.jpg",
        "/assets/images/wiczlino-2/diary/2022-04/20220519_133329.jpg",
        "/assets/images/wiczlino-2/diary/2022-04/20220519_133343.jpg",
        "/assets/images/wiczlino-2/diary/2022-04/20220519_133352.jpg",
        "/assets/images/wiczlino-2/diary/2022-04/20220519_133359.jpg",
        "/assets/images/wiczlino-2/diary/2022-04/20220519_133407.jpg",
        "/assets/images/wiczlino-2/diary/2022-04/20220519_133411.jpg",
        "/assets/images/wiczlino-2/diary/2022-04/20220519_133420.jpg",
        "/assets/images/wiczlino-2/diary/2022-04/20220519_133431.jpg",
        "/assets/images/wiczlino-2/diary/2022-04/20220519_133435.jpg",
        "/assets/images/wiczlino-2/diary/2022-04/20220519_133443.jpg",
        "/assets/images/wiczlino-2/diary/2022-04/20220519_133506.jpg",
        "/assets/images/wiczlino-2/diary/2022-04/20220519_133508.jpg",
        "/assets/images/wiczlino-2/diary/2022-04/20220519_133547.jpg",
        "/assets/images/wiczlino-2/diary/2022-04/20220519_133733.jpg",
        "/assets/images/wiczlino-2/diary/2022-04/20220519_133754.jpg",
        "/assets/images/wiczlino-2/diary/2022-04/20220519_133826.jpg",
        "/assets/images/wiczlino-2/diary/2022-04/20220519_133847.jpg",
        "/assets/images/wiczlino-2/diary/2022-04/20220519_133858.jpg",
        "/assets/images/wiczlino-2/diary/2022-04/20220519_133904.jpg",
        "/assets/images/wiczlino-2/diary/2022-04/20220519_133923.jpg",
        "/assets/images/wiczlino-2/diary/2022-04/20220519_133933.jpg",
        "/assets/images/wiczlino-2/diary/2022-04/20220519_134153.jpg",
        "/assets/images/wiczlino-2/diary/2022-04/20220519_134202.jpg",
      ],
      [
        "/assets/images/wiczlino-2/diary/2022-04/20220519_132914_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-04/20220519_133002_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-04/20220519_133011_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-04/20220519_133028_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-04/20220519_133042_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-04/20220519_133126_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-04/20220519_133146_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-04/20220519_133149_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-04/20220519_133203_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-04/20220519_133215_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-04/20220519_133223_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-04/20220519_133238_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-04/20220519_133258_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-04/20220519_133302_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-04/20220519_133314_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-04/20220519_133329_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-04/20220519_133343_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-04/20220519_133352_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-04/20220519_133359_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-04/20220519_133407_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-04/20220519_133411_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-04/20220519_133420_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-04/20220519_133431_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-04/20220519_133435_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-04/20220519_133443_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-04/20220519_133506_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-04/20220519_133508_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-04/20220519_133547_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-04/20220519_133733_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-04/20220519_133754_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-04/20220519_133826_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-04/20220519_133847_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-04/20220519_133858_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-04/20220519_133904_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-04/20220519_133923_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-04/20220519_133933_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-04/20220519_134153_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-04/20220519_134202_mini.jpg",
      ],
    ],
    alts: [],
  },
  {
    title: "offer.janowo.diary.timeline.march",
    year: 2022,
    pics: [
      [
        "/assets/images/wiczlino-2/diary/2022-03/20220413_134926.jpg",
        "/assets/images/wiczlino-2/diary/2022-03/20220413_134945.jpg",
        "/assets/images/wiczlino-2/diary/2022-03/20220413_140518.jpg",
        "/assets/images/wiczlino-2/diary/2022-03/20220413_141447.jpg",
        "/assets/images/wiczlino-2/diary/2022-03/20220413_141513.jpg",
        "/assets/images/wiczlino-2/diary/2022-03/20220413_141529.jpg",
        "/assets/images/wiczlino-2/diary/2022-03/20220413_141720.jpg",
        "/assets/images/wiczlino-2/diary/2022-03/20220413_141724.jpg",
        "/assets/images/wiczlino-2/diary/2022-03/20220413_142333.jpg",
        "/assets/images/wiczlino-2/diary/2022-03/20220413_142347.jpg",
        "/assets/images/wiczlino-2/diary/2022-03/20220413_142401.jpg",
        "/assets/images/wiczlino-2/diary/2022-03/20220413_142428.jpg",
        "/assets/images/wiczlino-2/diary/2022-03/20220413_142510.jpg",
        "/assets/images/wiczlino-2/diary/2022-03/20220413_142516.jpg",
        "/assets/images/wiczlino-2/diary/2022-03/20220413_142525.jpg",
        "/assets/images/wiczlino-2/diary/2022-03/20220413_142546.jpg",
        "/assets/images/wiczlino-2/diary/2022-03/20220413_142554.jpg",
        "/assets/images/wiczlino-2/diary/2022-03/20220413_142601.jpg",
        "/assets/images/wiczlino-2/diary/2022-03/20220413_142726.jpg",
        "/assets/images/wiczlino-2/diary/2022-03/20220413_142756.jpg",
        "/assets/images/wiczlino-2/diary/2022-03/20220413_142811.jpg",
        "/assets/images/wiczlino-2/diary/2022-03/20220413_142821.jpg",
        "/assets/images/wiczlino-2/diary/2022-03/20220413_142853.jpg",
        "/assets/images/wiczlino-2/diary/2022-03/20220413_142857.jpg",
        "/assets/images/wiczlino-2/diary/2022-03/20220413_142910.jpg",
        "/assets/images/wiczlino-2/diary/2022-03/20220413_143006.jpg",
        "/assets/images/wiczlino-2/diary/2022-03/20220413_143353.jpg",
        "/assets/images/wiczlino-2/diary/2022-03/20220413_143401.jpg",
        "/assets/images/wiczlino-2/diary/2022-03/20220413_143414.jpg",
      ],
      [
        "/assets/images/wiczlino-2/diary/2022-03/20220413_134926_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-03/20220413_134945_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-03/20220413_140518_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-03/20220413_141447_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-03/20220413_141513_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-03/20220413_141529_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-03/20220413_141720_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-03/20220413_141724_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-03/20220413_142333_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-03/20220413_142347_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-03/20220413_142401_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-03/20220413_142428_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-03/20220413_142510_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-03/20220413_142516_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-03/20220413_142525_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-03/20220413_142546_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-03/20220413_142554_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-03/20220413_142601_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-03/20220413_142726_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-03/20220413_142756_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-03/20220413_142811_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-03/20220413_142821_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-03/20220413_142853_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-03/20220413_142857_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-03/20220413_142910_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-03/20220413_143006_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-03/20220413_143353_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-03/20220413_143401_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-03/20220413_143414_mini.jpg",
      ],
    ],
    alts: [],
  },
  {
    title: "offer.janowo.diary.timeline.february",
    year: 2022,
    pics: [
      [
        "/assets/images/wiczlino-2/diary/2022-02/20220222_101824.jpg",
        "/assets/images/wiczlino-2/diary/2022-02/20220222_101838.jpg",
        "/assets/images/wiczlino-2/diary/2022-02/20220222_101903.jpg",
        "/assets/images/wiczlino-2/diary/2022-02/20220222_101907.jpg",
        "/assets/images/wiczlino-2/diary/2022-02/20220222_101909.jpg",
        "/assets/images/wiczlino-2/diary/2022-02/20220222_101934.jpg",
        "/assets/images/wiczlino-2/diary/2022-02/20220222_101949.jpg",
        "/assets/images/wiczlino-2/diary/2022-02/20220222_101953.jpg",
        "/assets/images/wiczlino-2/diary/2022-02/20220222_102111.jpg",
        "/assets/images/wiczlino-2/diary/2022-02/20220222_102114.jpg",
        "/assets/images/wiczlino-2/diary/2022-02/20220222_102122.jpg",
      ],
      [
        "/assets/images/wiczlino-2/diary/2022-02/20220222_101824_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-02/20220222_101838_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-02/20220222_101903_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-02/20220222_101907_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-02/20220222_101909_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-02/20220222_101934_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-02/20220222_101949_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-02/20220222_101953_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-02/20220222_102111_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-02/20220222_102114_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-02/20220222_102122_mini.jpg",
      ],
    ],
    alts: [],
  },
  {
    title: "offer.janowo.diary.timeline.january",
    year: 2022,
    pics: [
      [
        "/assets/images/wiczlino-2/diary/2022-01/20220108_114236.jpg",
        "/assets/images/wiczlino-2/diary/2022-01/20220108_114237.jpg",
        "/assets/images/wiczlino-2/diary/2022-01/20220108_114238.jpg",
        "/assets/images/wiczlino-2/diary/2022-01/20220108_114240.jpg",
        "/assets/images/wiczlino-2/diary/2022-01/20220108_114241.jpg",
        "/assets/images/wiczlino-2/diary/2022-01/20220108_114322.jpg",
        "/assets/images/wiczlino-2/diary/2022-01/20220108_114324.jpg",
        "/assets/images/wiczlino-2/diary/2022-01/20220108_114325.jpg",
        "/assets/images/wiczlino-2/diary/2022-01/20220108_114326.jpg",
        "/assets/images/wiczlino-2/diary/2022-01/20220108_114328.jpg",
        "/assets/images/wiczlino-2/diary/2022-01/20220108_114329.jpg",
      ],
      [
        "/assets/images/wiczlino-2/diary/2022-01/20220108_114236_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-01/20220108_114237_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-01/20220108_114238_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-01/20220108_114240_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-01/20220108_114241_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-01/20220108_114322_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-01/20220108_114324_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-01/20220108_114325_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-01/20220108_114326_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-01/20220108_114328_mini.jpg",
        "/assets/images/wiczlino-2/diary/2022-01/20220108_114329_mini.jpg",
      ],
    ],
    alts: [],
  },
  {
    title: "offer.janowo.diary.timeline.december",
    year: 2021,
    pics: [
      [
        // "/assets/images/wiczlino-2/diary/2021-12/20211120_092031.jpg",
        // "/assets/images/wiczlino-2/diary/2021-12/20211120_092034.jpg",
        // "/assets/images/wiczlino-2/diary/2021-12/20211120_092036.jpg",
        // "/assets/images/wiczlino-2/diary/2021-12/20211120_092038.jpg",
        // "/assets/images/wiczlino-2/diary/2021-12/20211120_092040.jpg",
        "/assets/images/wiczlino-2/diary/2021-12/20211120_092042.jpg",
        "/assets/images/wiczlino-2/diary/2021-12/20211120_092044.jpg",
      ],
      [
        // "/assets/images/wiczlino-2/diary/2021-12/20211120_092031_mini.jpg",
        // "/assets/images/wiczlino-2/diary/2021-12/20211120_092034_mini.jpg",
        // "/assets/images/wiczlino-2/diary/2021-12/20211120_092036_mini.jpg",
        // "/assets/images/wiczlino-2/diary/2021-12/20211120_092038_mini.jpg",
        // "/assets/images/wiczlino-2/diary/2021-12/20211120_092040_mini.jpg",
        "/assets/images/wiczlino-2/diary/2021-12/20211120_092042_mini.jpg",
        "/assets/images/wiczlino-2/diary/2021-12/20211120_092044_mini.jpg",
      ],
    ],
    alts: [],
  },
  {
    title: "offer.janowo.diary.timeline.november",
    year: 2021,
    pics: [
      [
        "/assets/images/wiczlino-2/diary/2021-11/20211120_092021.jpg",
        "/assets/images/wiczlino-2/diary/2021-11/20211120_092022.jpg",
        "/assets/images/wiczlino-2/diary/2021-11/20211120_092031.jpg",
        "/assets/images/wiczlino-2/diary/2021-11/20211120_092034.jpg",
        "/assets/images/wiczlino-2/diary/2021-11/20211120_092036.jpg",
        "/assets/images/wiczlino-2/diary/2021-11/20211120_092038.jpg",
        "/assets/images/wiczlino-2/diary/2021-11/20211120_092040.jpg",
      ],
      [
        "/assets/images/wiczlino-2/diary/2021-11/20211120_092021_mini.jpg",
        "/assets/images/wiczlino-2/diary/2021-11/20211120_092022_mini.jpg",
        "/assets/images/wiczlino-2/diary/2021-11/20211120_092031_mini.jpg",
        "/assets/images/wiczlino-2/diary/2021-11/20211120_092034_mini.jpg",
        "/assets/images/wiczlino-2/diary/2021-11/20211120_092036_mini.jpg",
        "/assets/images/wiczlino-2/diary/2021-11/20211120_092038_mini.jpg",
        "/assets/images/wiczlino-2/diary/2021-11/20211120_092040_mini.jpg",
      ],
    ],
    alts: [],
  },
  {
    title: "offer.janowo.diary.timeline.october",
    year: 2021,
    pics: [
      [
        "/assets/images/wiczlino-2/diary/2021-10/wiczlino_10_2021-01.jpg",
        "/assets/images/wiczlino-2/diary/2021-10/wiczlino_10_2021-02.jpg",
        "/assets/images/wiczlino-2/diary/2021-10/wiczlino_10_2021-03.jpg",
        "/assets/images/wiczlino-2/diary/2021-10/wiczlino_10_2021-04.jpg",
        "/assets/images/wiczlino-2/diary/2021-10/wiczlino_10_2021-05.jpg",
        "/assets/images/wiczlino-2/diary/2021-10/wiczlino_10_2021-07.jpg",
        "/assets/images/wiczlino-2/diary/2021-10/wiczlino_10_2021-08.jpg",
        "/assets/images/wiczlino-2/diary/2021-10/wiczlino_10_2021-09.jpg",
        "/assets/images/wiczlino-2/diary/2021-10/wiczlino_10_2021-10.jpg",
        "/assets/images/wiczlino-2/diary/2021-10/wiczlino_10_2021-11.jpg",
        "/assets/images/wiczlino-2/diary/2021-10/wiczlino_10_2021-12.jpg",
        "/assets/images/wiczlino-2/diary/2021-10/wiczlino_10_2021-14.jpg",
      ],
      [
        "/assets/images/wiczlino-2/diary/2021-10/wiczlino_10_2021-01_mini.jpg",
        "/assets/images/wiczlino-2/diary/2021-10/wiczlino_10_2021-02_mini.jpg",
        "/assets/images/wiczlino-2/diary/2021-10/wiczlino_10_2021-03_mini.jpg",
        "/assets/images/wiczlino-2/diary/2021-10/wiczlino_10_2021-04_mini.jpg",
        "/assets/images/wiczlino-2/diary/2021-10/wiczlino_10_2021-05_mini.jpg",
        "/assets/images/wiczlino-2/diary/2021-10/wiczlino_10_2021-07_mini.jpg",
        "/assets/images/wiczlino-2/diary/2021-10/wiczlino_10_2021-08_mini.jpg",
        "/assets/images/wiczlino-2/diary/2021-10/wiczlino_10_2021-09_mini.jpg",
        "/assets/images/wiczlino-2/diary/2021-10/wiczlino_10_2021-10_mini.jpg",
        "/assets/images/wiczlino-2/diary/2021-10/wiczlino_10_2021-11_mini.jpg",
        "/assets/images/wiczlino-2/diary/2021-10/wiczlino_10_2021-12_mini.jpg",
        "/assets/images/wiczlino-2/diary/2021-10/wiczlino_10_2021-14_mini.jpg",
      ],
    ],
    alts: [],
  },
  {
    title: "offer.janowo.diary.timeline.september",
    year: 2021,
    pics: [
      [
        "https://willbud.pl/diary/new-wiczlino/wrz2021_1.jpg",
        "https://willbud.pl/diary/new-wiczlino/wrz2021_2.jpg",
        "https://willbud.pl/diary/new-wiczlino/wrz2021_3.jpg",
        "https://willbud.pl/diary/new-wiczlino/wrz2021_4.jpg",
        "https://willbud.pl/diary/new-wiczlino/wrz2021_5.jpg",
        "https://willbud.pl/diary/new-wiczlino/wrz2021_6.jpg",
        "https://willbud.pl/diary/new-wiczlino/wrz2021_7.jpg",
        "https://willbud.pl/diary/new-wiczlino/wrz2021_8.jpg",
        "https://willbud.pl/diary/new-wiczlino/wrz2021_9.jpg",
        "https://willbud.pl/diary/new-wiczlino/wrz2021_10.jpg",
        "https://willbud.pl/diary/new-wiczlino/wrz2021_11.jpg",
        "https://willbud.pl/diary/new-wiczlino/wrz2021_12.jpg",
        "https://willbud.pl/diary/new-wiczlino/wrz2021_13.jpg",
        "https://willbud.pl/diary/new-wiczlino/wrz2021_14.jpg",
        "https://willbud.pl/diary/new-wiczlino/wrz2021_15.jpg",
        "https://willbud.pl/diary/new-wiczlino/wrz2021_16.jpg",
        "https://willbud.pl/diary/new-wiczlino/wrz2021_17.jpg",
        "https://willbud.pl/diary/new-wiczlino/wrz2021_18.jpg",
      ],
      [
        "https://willbud.pl/diary/new-wiczlino/wrz2021_min1.jpg",
        "https://willbud.pl/diary/new-wiczlino/wrz2021_min2.jpg",
        "https://willbud.pl/diary/new-wiczlino/wrz2021_min3.jpg",
        "https://willbud.pl/diary/new-wiczlino/wrz2021_min4.jpg",
        "https://willbud.pl/diary/new-wiczlino/wrz2021_min5.jpg",
        "https://willbud.pl/diary/new-wiczlino/wrz2021_min6.jpg",
        "https://willbud.pl/diary/new-wiczlino/wrz2021_min7.jpg",
        "https://willbud.pl/diary/new-wiczlino/wrz2021_min8.jpg",
        "https://willbud.pl/diary/new-wiczlino/wrz2021_min9.jpg",
        "https://willbud.pl/diary/new-wiczlino/wrz2021_min10.jpg",
        "https://willbud.pl/diary/new-wiczlino/wrz2021_min11.jpg",
        "https://willbud.pl/diary/new-wiczlino/wrz2021_min12.jpg",
        "https://willbud.pl/diary/new-wiczlino/wrz2021_min13.jpg",
        "https://willbud.pl/diary/new-wiczlino/wrz2021_min14.jpg",
        "https://willbud.pl/diary/new-wiczlino/wrz2021_min15.jpg",
        "https://willbud.pl/diary/new-wiczlino/wrz2021_min16.jpg",
        "https://willbud.pl/diary/new-wiczlino/wrz2021_min17.jpg",
        "https://willbud.pl/diary/new-wiczlino/wrz2021_min18.jpg",
      ],
    ],
    alts: [],
  },
  {
    title: "offer.janowo.diary.timeline.august",
    year: 2021,
    pics: [
      [
        "https://willbud.pl/diary/new-wiczlino/sie2021_1.jpg",
        "https://willbud.pl/diary/new-wiczlino/sie2021_2.jpg",
        "https://willbud.pl/diary/new-wiczlino/sie2021_3.jpg",
        "https://willbud.pl/diary/new-wiczlino/sie2021_4.jpg",
        "https://willbud.pl/diary/new-wiczlino/sie2021_5.jpg",
        "https://willbud.pl/diary/new-wiczlino/sie2021_6.jpg",
        "https://willbud.pl/diary/new-wiczlino/sie2021_7.jpg",
        "https://willbud.pl/diary/new-wiczlino/sie2021_8.jpg",
        "https://willbud.pl/diary/new-wiczlino/sie2021_9.jpg",
        "https://willbud.pl/diary/new-wiczlino/sie2021_10.jpg",
        "https://willbud.pl/diary/new-wiczlino/sie2021_11.jpg",
        "https://willbud.pl/diary/new-wiczlino/sie2021_12.jpg",
        "https://willbud.pl/diary/new-wiczlino/sie2021_13.jpg",
        "https://willbud.pl/diary/new-wiczlino/sie2021_14.jpg",
        "https://willbud.pl/diary/new-wiczlino/sie2021_15.jpg",
        "https://willbud.pl/diary/new-wiczlino/sie2021_16.jpg",
        "https://willbud.pl/diary/new-wiczlino/sie2021_17.jpg",
        "https://willbud.pl/diary/new-wiczlino/sie2021_18.jpg",
      ],
      [
        "https://willbud.pl/diary/new-wiczlino/sie2021_min1.jpg",
        "https://willbud.pl/diary/new-wiczlino/sie2021_min2.jpg",
        "https://willbud.pl/diary/new-wiczlino/sie2021_min3.jpg",
        "https://willbud.pl/diary/new-wiczlino/sie2021_min4.jpg",
        "https://willbud.pl/diary/new-wiczlino/sie2021_min5.jpg",
        "https://willbud.pl/diary/new-wiczlino/sie2021_min6.jpg",
        "https://willbud.pl/diary/new-wiczlino/sie2021_min7.jpg",
        "https://willbud.pl/diary/new-wiczlino/sie2021_min8.jpg",
        "https://willbud.pl/diary/new-wiczlino/sie2021_min9.jpg",
        "https://willbud.pl/diary/new-wiczlino/sie2021_min10.jpg",
        "https://willbud.pl/diary/new-wiczlino/sie2021_min11.jpg",
        "https://willbud.pl/diary/new-wiczlino/sie2021_min12.jpg",
        "https://willbud.pl/diary/new-wiczlino/sie2021_min13.jpg",
        "https://willbud.pl/diary/new-wiczlino/sie2021_min14.jpg",
        "https://willbud.pl/diary/new-wiczlino/sie2021_min15.jpg",
        "https://willbud.pl/diary/new-wiczlino/sie2021_min16.jpg",
        "https://willbud.pl/diary/new-wiczlino/sie2021_min17.jpg",
        "https://willbud.pl/diary/new-wiczlino/sie2021_min18.jpg",
      ],
    ],
    alts: [],
  },
  {
    title: "offer.janowo.diary.timeline.july",
    year: 2021,
    pics: [
      [
        "https://willbud.pl/diary/new-wiczlino/lip2021_1.jpg",
        "https://willbud.pl/diary/new-wiczlino/lip2021_2.jpg",
        "https://willbud.pl/diary/new-wiczlino/lip2021_3.jpg",
        "https://willbud.pl/diary/new-wiczlino/lip2021_4.jpg",
        "https://willbud.pl/diary/new-wiczlino/lip2021_5.jpg",
        "https://willbud.pl/diary/new-wiczlino/lip2021_6.jpg",
        "https://willbud.pl/diary/new-wiczlino/lip2021_7.jpg",
        "https://willbud.pl/diary/new-wiczlino/lip2021_8.jpg",
        "https://willbud.pl/diary/new-wiczlino/lip2021_9.jpg",
        "https://willbud.pl/diary/new-wiczlino/lip2021_10.jpg",
        "https://willbud.pl/diary/new-wiczlino/lip2021_11.jpg",
        "https://willbud.pl/diary/new-wiczlino/lip2021_12.jpg",
        "https://willbud.pl/diary/new-wiczlino/lip2021_13.jpg",
        "https://willbud.pl/diary/new-wiczlino/lip2021_14.jpg",
        "https://willbud.pl/diary/new-wiczlino/lip2021_15.jpg",
        "https://willbud.pl/diary/new-wiczlino/lip2021_16.jpg",
        "https://willbud.pl/diary/new-wiczlino/lip2021_17.jpg",
        "https://willbud.pl/diary/new-wiczlino/lip2021_18.jpg",
        "https://willbud.pl/diary/new-wiczlino/lip2021_19.jpg",
        "https://willbud.pl/diary/new-wiczlino/lip2021_20.jpg",
        "https://willbud.pl/diary/new-wiczlino/lip2021_21.jpg",
        "https://willbud.pl/diary/new-wiczlino/lip2021_22.jpg",
        "https://willbud.pl/diary/new-wiczlino/lip2021_23.jpg",
        "https://willbud.pl/diary/new-wiczlino/lip2021_24.jpg",
      ],
      [
        "https://willbud.pl/diary/new-wiczlino/lip2021_min1.jpg",
        "https://willbud.pl/diary/new-wiczlino/lip2021_min2.jpg",
        "https://willbud.pl/diary/new-wiczlino/lip2021_min3.jpg",
        "https://willbud.pl/diary/new-wiczlino/lip2021_min4.jpg",
        "https://willbud.pl/diary/new-wiczlino/lip2021_min5.jpg",
        "https://willbud.pl/diary/new-wiczlino/lip2021_min6.jpg",
        "https://willbud.pl/diary/new-wiczlino/lip2021_min7.jpg",
        "https://willbud.pl/diary/new-wiczlino/lip2021_min8.jpg",
        "https://willbud.pl/diary/new-wiczlino/lip2021_min9.jpg",
        "https://willbud.pl/diary/new-wiczlino/lip2021_min10.jpg",
        "https://willbud.pl/diary/new-wiczlino/lip2021_min11.jpg",
        "https://willbud.pl/diary/new-wiczlino/lip2021_min12.jpg",
        "https://willbud.pl/diary/new-wiczlino/lip2021_min13.jpg",
        "https://willbud.pl/diary/new-wiczlino/lip2021_min14.jpg",
        "https://willbud.pl/diary/new-wiczlino/lip2021_min15.jpg",
        "https://willbud.pl/diary/new-wiczlino/lip2021_min16.jpg",
        "https://willbud.pl/diary/new-wiczlino/lip2021_min17.jpg",
        "https://willbud.pl/diary/new-wiczlino/lip2021_min18.jpg",
        "https://willbud.pl/diary/new-wiczlino/lip2021_min19.jpg",
        "https://willbud.pl/diary/new-wiczlino/lip2021_min20.jpg",
        "https://willbud.pl/diary/new-wiczlino/lip2021_min21.jpg",
        "https://willbud.pl/diary/new-wiczlino/lip2021_min22.jpg",
        "https://willbud.pl/diary/new-wiczlino/lip2021_min23.jpg",
        "https://willbud.pl/diary/new-wiczlino/lip2021_min24.jpg",
      ],
    ],
    alts: [],
  },
  {
    title: "offer.janowo.diary.timeline.may",
    year: 2021,
    pics: [
      [
        "https://willbud.pl/diary/new-wiczlino/maj2021_1.jpg",
        "https://willbud.pl/diary/new-wiczlino/maj2021_2.jpg",
        "https://willbud.pl/diary/new-wiczlino/maj2021_3.jpg",
        "https://willbud.pl/diary/new-wiczlino/maj2021_4.jpg",
        "https://willbud.pl/diary/new-wiczlino/maj2021_5.jpg",
        "https://willbud.pl/diary/new-wiczlino/maj2021_6.jpg",
        "https://willbud.pl/diary/new-wiczlino/maj2021_7.jpg",
        "https://willbud.pl/diary/new-wiczlino/maj2021_8.jpg",
        "https://willbud.pl/diary/new-wiczlino/maj2021_9.jpg",
        "https://willbud.pl/diary/new-wiczlino/maj2021_10.jpg",
        "https://willbud.pl/diary/new-wiczlino/maj2021_11.jpg",
        "https://willbud.pl/diary/new-wiczlino/maj2021_12.jpg",
        "https://willbud.pl/diary/new-wiczlino/maj2021_13.jpg",
        "https://willbud.pl/diary/new-wiczlino/maj2021_14.jpg",
        "https://willbud.pl/diary/new-wiczlino/maj2021_15.jpg",
        "https://willbud.pl/diary/new-wiczlino/maj2021_16.jpg",
        "https://willbud.pl/diary/new-wiczlino/maj2021_17.jpg",
        "https://willbud.pl/diary/new-wiczlino/maj2021_18.jpg",
      ],
      [
        "https://willbud.pl/diary/new-wiczlino/maj2021_min1.jpg",
        "https://willbud.pl/diary/new-wiczlino/maj2021_min2.jpg",
        "https://willbud.pl/diary/new-wiczlino/maj2021_min3.jpg",
        "https://willbud.pl/diary/new-wiczlino/maj2021_min4.jpg",
        "https://willbud.pl/diary/new-wiczlino/maj2021_min5.jpg",
        "https://willbud.pl/diary/new-wiczlino/maj2021_min6.jpg",
        "https://willbud.pl/diary/new-wiczlino/maj2021_min7.jpg",
        "https://willbud.pl/diary/new-wiczlino/maj2021_min8.jpg",
        "https://willbud.pl/diary/new-wiczlino/maj2021_min9.jpg",
        "https://willbud.pl/diary/new-wiczlino/maj2021_min10.jpg",
        "https://willbud.pl/diary/new-wiczlino/maj2021_min11.jpg",
        "https://willbud.pl/diary/new-wiczlino/maj2021_min12.jpg",
        "https://willbud.pl/diary/new-wiczlino/maj2021_min13.jpg",
        "https://willbud.pl/diary/new-wiczlino/maj2021_min14.jpg",
        "https://willbud.pl/diary/new-wiczlino/maj2021_min15.jpg",
        "https://willbud.pl/diary/new-wiczlino/maj2021_min16.jpg",
        "https://willbud.pl/diary/new-wiczlino/maj2021_min17.jpg",
        "https://willbud.pl/diary/new-wiczlino/maj2021_min18.jpg",
      ],
    ],
    alts: [],
  },
  {
    title: "offer.janowo.diary.timeline.april",
    year: 2021,
    pics: [
      [
        "https://willbud.pl/diary/new-wiczlino/kwie2021-1.jpg",
        "https://willbud.pl/diary/new-wiczlino/kwie2021-2.jpg",
        "https://willbud.pl/diary/new-wiczlino/kwie2021-3.jpg",
        "https://willbud.pl/diary/new-wiczlino/kwie2021-4.jpg",
        "https://willbud.pl/diary/new-wiczlino/kwie2021-5.jpg",
        "https://willbud.pl/diary/new-wiczlino/kwie2021-6.jpg",
        "https://willbud.pl/diary/new-wiczlino/kwie2021-7.jpg",
        "https://willbud.pl/diary/new-wiczlino/kwie2021-8.jpg",
        "https://willbud.pl/diary/new-wiczlino/kwie2021-9.jpg",
        "https://willbud.pl/diary/new-wiczlino/kwie2021-10.jpg",
        "https://willbud.pl/diary/new-wiczlino/kwie2021-11.jpg",
        "https://willbud.pl/diary/new-wiczlino/kwie2021-12.jpg",
      ],
      [
        "https://willbud.pl/diary/new-wiczlino/kwie2021-min1.jpg",
        "https://willbud.pl/diary/new-wiczlino/kwie2021-min2.jpg",
        "https://willbud.pl/diary/new-wiczlino/kwie2021-min3.jpg",
        "https://willbud.pl/diary/new-wiczlino/kwie2021-min4.jpg",
        "https://willbud.pl/diary/new-wiczlino/kwie2021-min5.jpg",
        "https://willbud.pl/diary/new-wiczlino/kwie2021-min6.jpg",
        "https://willbud.pl/diary/new-wiczlino/kwie2021-min7.jpg",
        "https://willbud.pl/diary/new-wiczlino/kwie2021-min8.jpg",
        "https://willbud.pl/diary/new-wiczlino/kwie2021-min9.jpg",
        "https://willbud.pl/diary/new-wiczlino/kwie2021-min10.jpg",
        "https://willbud.pl/diary/new-wiczlino/kwie2021-min11.jpg",
        "https://willbud.pl/diary/new-wiczlino/kwie2021-min12.jpg",
      ],
    ],
    alts: [],
  },
  {
    title: "offer.janowo.diary.timeline.march",
    year: 2021,
    pics: [
      [
        "https://willbud.pl/diary/new-wiczlino/budmar2021-1.jpg",
        "https://willbud.pl/diary/new-wiczlino/budmar2021-2.jpg",
        "https://willbud.pl/diary/new-wiczlino/budmar2021-3.jpg",
        "https://willbud.pl/diary/new-wiczlino/budmar2021-4.jpg",
        "https://willbud.pl/diary/new-wiczlino/budmar2021-5.jpg",
        "https://willbud.pl/diary/new-wiczlino/budmar2021-6.jpg",
        "https://willbud.pl/diary/new-wiczlino/budmar2021-7.jpg",
        "https://willbud.pl/diary/new-wiczlino/budmar2021-8.jpg",
        "https://willbud.pl/diary/new-wiczlino/budmar2021-9.jpg",
        "https://willbud.pl/diary/new-wiczlino/budmar2021-10.jpg",
        "https://willbud.pl/diary/new-wiczlino/budmar2021-11.jpg",
        "https://willbud.pl/diary/new-wiczlino/budmar2021-12.jpg",
        "https://willbud.pl/diary/new-wiczlino/budmar2021-13.jpg",
        "https://willbud.pl/diary/new-wiczlino/budmar2021-14.jpg",
        "https://willbud.pl/diary/new-wiczlino/budmar2021-15.jpg",
        "https://willbud.pl/diary/new-wiczlino/budmar2021-16.jpg",
        "https://willbud.pl/diary/new-wiczlino/budmar2021-17.jpg",
      ],
      [
        "https://willbud.pl/diary/new-wiczlino/budmar2021-min1.jpg",
        "https://willbud.pl/diary/new-wiczlino/budmar2021-min2.jpg",
        "https://willbud.pl/diary/new-wiczlino/budmar2021-min3.jpg",
        "https://willbud.pl/diary/new-wiczlino/budmar2021-min4.jpg",
        "https://willbud.pl/diary/new-wiczlino/budmar2021-min5.jpg",
        "https://willbud.pl/diary/new-wiczlino/budmar2021-min6.jpg",
        "https://willbud.pl/diary/new-wiczlino/budmar2021-min7.jpg",
        "https://willbud.pl/diary/new-wiczlino/budmar2021-min8.jpg",
        "https://willbud.pl/diary/new-wiczlino/budmar2021-min9.jpg",
        "https://willbud.pl/diary/new-wiczlino/budmar2021-min10.jpg",
        "https://willbud.pl/diary/new-wiczlino/budmar2021-min11.jpg",
        "https://willbud.pl/diary/new-wiczlino/budmar2021-min12.jpg",
        "https://willbud.pl/diary/new-wiczlino/budmar2021-min13.jpg",
        "https://willbud.pl/diary/new-wiczlino/budmar2021-min14.jpg",
        "https://willbud.pl/diary/new-wiczlino/budmar2021-min15.jpg",
        "https://willbud.pl/diary/new-wiczlino/budmar2021-min16.jpg",
        "https://willbud.pl/diary/new-wiczlino/budmar2021-min17.jpg",
      ],
    ],
    alts: [],
  },
  {
    title: "offer.janowo.diary.timeline.august",
    year: 2020,
    pics: [
      [
        "https://willbud.pl/diary/new-wiczlino/budmaj-1.jpg",
        "https://willbud.pl/diary/new-wiczlino/budmaj-2.jpg",
        "https://willbud.pl/diary/new-wiczlino/budmaj-3.jpg",
        "https://willbud.pl/diary/new-wiczlino/budmaj-4.jpg",
        "https://willbud.pl/diary/new-wiczlino/budmaj-5.jpg",
        "https://willbud.pl/diary/new-wiczlino/budmaj-6.jpg",
        "https://willbud.pl/diary/new-wiczlino/budmaj-7.jpg",
        "https://willbud.pl/diary/new-wiczlino/budmaj-8.jpg",
        "https://willbud.pl/diary/new-wiczlino/budmaj-9.jpg",
        "https://willbud.pl/diary/new-wiczlino/budmaj-10.jpg",
        "https://willbud.pl/diary/new-wiczlino/budmaj-11.jpg",
        "https://willbud.pl/diary/new-wiczlino/budmaj-12.jpg",
        "https://willbud.pl/diary/new-wiczlino/budmaj-13.jpg",
        "https://willbud.pl/diary/new-wiczlino/budmaj-14.jpg",
        "https://willbud.pl/diary/new-wiczlino/budmaj-15.jpg",
        "https://willbud.pl/diary/new-wiczlino/budmaj-16.jpg",
        "https://willbud.pl/diary/new-wiczlino/budmaj-17.jpg",
        "https://willbud.pl/diary/new-wiczlino/budmaj-18.jpg",
      ],
      [
        "https://willbud.pl/diary/new-wiczlino/budmaj-min1.jpg",
        "https://willbud.pl/diary/new-wiczlino/budmaj-min2.jpg",
        "https://willbud.pl/diary/new-wiczlino/budmaj-min3.jpg",
        "https://willbud.pl/diary/new-wiczlino/budmaj-min4.jpg",
        "https://willbud.pl/diary/new-wiczlino/budmaj-min5.jpg",
        "https://willbud.pl/diary/new-wiczlino/budmaj-min6.jpg",
        "https://willbud.pl/diary/new-wiczlino/budmaj-min7.jpg",
        "https://willbud.pl/diary/new-wiczlino/budmaj-min8.jpg",
        "https://willbud.pl/diary/new-wiczlino/budmaj-min9.jpg",
        "https://willbud.pl/diary/new-wiczlino/budmaj-min10.jpg",
        "https://willbud.pl/diary/new-wiczlino/budmaj-min11.jpg",
        "https://willbud.pl/diary/new-wiczlino/budmaj-min12.jpg",
        "https://willbud.pl/diary/new-wiczlino/budmaj-min13.jpg",
        "https://willbud.pl/diary/new-wiczlino/budmaj-min14.jpg",
        "https://willbud.pl/diary/new-wiczlino/budmaj-min15.jpg",
        "https://willbud.pl/diary/new-wiczlino/budmaj-min16.jpg",
        "https://willbud.pl/diary/new-wiczlino/budmaj-min17.jpg",
        "https://willbud.pl/diary/new-wiczlino/budmaj-min18.jpg",
      ],
    ],
    alts: [
      "Mieszkania Janowo Park B1",
      "Osiedle Janowo Park Budowa",
      "Mieszkanie z widokiem na park",
      "Janowo Park Rumia sierpień",
      "Osiedle Janowo Park w sierpniu",
      "Nowe budynki na Osiedlu Janowo Park w Rumi",
      "Nowe budynki na Osiedlu Janowo Park w Rumi",
      "Nowe budynki na Osiedlu Janowo Park w Rumi",
    ],
  },
]
